import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ClearTable as ClearTableIcon, Refresh, WithTooltip } from 'basic-components';
import { TimeService } from 'dashboard-services';
import { globalActions } from 'primary-components';

import { ExcelUtils } from 'utils';

import './LoadedFileRow.scss';

const LoadedFileRow = ({ arrivalTime, fileName, refreshFile, fid, address, ...props }) => {
  const dispatch = useDispatch(),
        definedTime = useSelector(state => state.settingsState.definedTime),
        uploadDate = useMemo(() => TimeService.getUserFormattedDateTime(arrivalTime, definedTime).split(" "), [arrivalTime, definedTime]),
        refreshFileLocal = useCallback(() => 
          refreshFile({ file: { arrivalTime, fileName, fid, address, ...props } })
            .then(() => dispatch(globalActions.getMessageStore()).success("Latest data downloaded."))
        , [address, arrivalTime, dispatch, fid, fileName, props, refreshFile]),
        goToAddress = useCallback(() => ExcelUtils.goToAddress(address), [address]),
        clearTable = useCallback(() => ExcelUtils.clearTableWithFormulaAddress(address), [address])

  return (
    <div className="ng-office-app__authed__content__body__item__row ng-office-app__authed__content__body__item__row--loaded-file">
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--name">
        {fileName}
      </div>
      <div 
          className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--address"
          onClick={goToAddress}
      >
        {address}
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--last-executed">
        <div className="ng-office-app__authed__content__body__item__row__cell__date">
          {uploadDate[0]}
        </div>
        <div className="ng-office-app__authed__content__body__item__row__cell__time">
          {uploadDate[1]} ({definedTime})
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--icons">
        <WithTooltip
            data-place="left"
            text="Download latest file."
        >
          <div 
              className="ng-office-app__authed__content__body__item__row__cell__icon"
              onClick={refreshFileLocal}
          >
            <Refresh
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
        <WithTooltip
            data-place="left"
            text="Clear table and formula."
        >
          <div 
              className="ng-office-app__authed__content__body__item__row__cell__icon"
              onClick={clearTable}
          >
            <ClearTableIcon
                color="red-dark"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
      </div>
    </div>
  )
}
LoadedFileRow.defaultProps = {
  address: "",
  arrivalTime: "",
  fileName: "",
}

LoadedFileRow.propTypes = {
  address: PropTypes.string,
  arrivalTime: PropTypes.string,
  fid: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  refreshFile: PropTypes.func.isRequired,
}

export default LoadedFileRow;