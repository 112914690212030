import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, getColor, LoadMoreDropdown, Tag } from 'basic-components';
import { DropdownService, HooksService, ReduxService, tsKeysTypes } from 'dashboard-services';

import { basicTimeseriesActions, keyTimeseriesActions } from 'actions/timeseries';
import getApiConfig from 'api/ApiConfig';

import MetadataLabel from '../ListView/Settings/OutputType/MetadataLabel';
import Preview from './Preview';

import { When } from 'react-if';

import classNames from 'classnames';

const ALL = { label: "All", value: "*" }
const KeyView = () => {
  const dispatch = useDispatch(),
        groupName = useSelector(state => state.timeseriesState.keyState.groupName),
        columnsRaw = useSelector(state => state.timeseriesState.keyState.columns, shallowEqual),
        symbols = useSelector(state => state.timeseriesState.keyState.symbols, shallowEqual),
        metadatas = useSelector(state => state.timeseriesState.keyState.metadatas, shallowEqual),
        [key, setKey] = useState(),
        [keyType, setKeyType] = useState(),
        [value, setValue] = useState(),
        keys = useSelector(state => state.timeseriesState.listState.keys),
        keysExist = useMemo(() => keys?.length > 0, [keys]),
        columns = useMemo(() => columnsRaw?.map(c => c === ALL.value ? ALL : { label: c, value: c }), [columnsRaw]),
        isMounted = HooksService.useIsMounted(),
        onChangeRedux = useCallback((val, { name }) => dispatch(keyTimeseriesActions.onChange(val, name)), [dispatch]),
        onChangeGroupName = useCallback((val, { name }) => {
          onChangeRedux(val, { name })
          dispatch(keyTimeseriesActions.onChange({}, 'symbols'))
          dispatch(keyTimeseriesActions.onChange({}, 'metadatas'))
        }, [dispatch, onChangeRedux]),

        { searchGroups, hasMoreGroups } = DropdownService.useSearchGroups(dispatch(getApiConfig()), isMounted, { symbolsOnly: true }),
        { hasMoreSymbolKeys, searchSymbolKeys: searchSymbolKeysRaw } = DropdownService.useSearchSymbolKeys(dispatch(getApiConfig()), { 
          groupName, 
          metadata: true, 
          useEs: true, 
          symbols: Object.fromEntries(Object.entries(symbols).map(([k, v]) => [k, v === "*" ? [] : [v]])),
          metadatas: Object.fromEntries(Object.entries(metadatas).map(([k, v]) => [k, v === "*" ? [] : [v]])),
        }),
        searchSymbolKeys = useCallback((input, size) =>
          searchSymbolKeysRaw(input, size)
            .then((items = []) => items.map(item => ReduxService.updateObject(item, {
              label: item.type === tsKeysTypes.METADATA ? (
                <MetadataLabel 
                    isSmall
                    name={item.label}
                />
              ) : item.label,
              color: item.type === tsKeysTypes.METADATA ? getColor("grey-dark") : undefined,
            })))
        , [searchSymbolKeysRaw]),
        { hasMoreSymbolValues, searchSymbolValues } = DropdownService.useSearchSymbolValues(dispatch(getApiConfig()), { 
          key, 
          symbols: Object.fromEntries(Object.entries(symbols).map(([k, v]) => [k, v === "*" ? [] : [v]])),
          groupName,
          metadata: true,
          metadatas: Object.fromEntries(Object.entries(metadatas).map(([k, v]) => [k, v === "*" ? [] : [v]])),
          useEs: true 
        }),
        searchValues = useCallback((input, size) => 
          searchSymbolValues(input, size)
            .then((items = []) => {
              try {
                if(!input || ALL.label.toLowerCase().search(input.toLowerCase()) > -1) {
                  return [ALL].concat(items)
                }
              } catch(e) {
                // invalid regexp, we will skip it
              }
              return items;
            })
        , [searchSymbolValues]),
        { hasMoreColumns, searchColumns: searchColumnsRaw } = DropdownService.useSearchColumns(dispatch(getApiConfig()), { 
          symbols: Object.fromEntries(Object.entries(symbols).map(([k, v]) => [k, v === "*" ? undefined : v])),
          groupName,
          metadata: true,
          metadatas: Object.fromEntries(Object.entries(metadatas).map(([k, v]) => [k, v === "*" ? undefined : v])),
          useEs: true 
        }),
        searchColumns = useCallback((input, size) => 
          searchColumnsRaw(input, size)
            .then(items => {
              try {
                if(!input || ALL.label.toLowerCase().search(input.toLowerCase()) > -1) {
                  return [ALL].concat(items)
                }
              } catch(e) {
                // invalid regexp, we will skip it
              }
              return items;
            })
        , [searchColumnsRaw]),
        removeSymbolKey = useCallback(key => {
          dispatch(keyTimeseriesActions.updateObject(s => {
            const newS = ReduxService.updateObject(s)
            delete newS[key]
            return newS;
          }, "symbols"))
        }, [dispatch]),
        removeMetadataKey = useCallback(key => {
          dispatch(keyTimeseriesActions.updateObject(m => {
            const newM = ReduxService.updateObject(m)
            delete newM[key]
            return newM;
          }, "metadatas"))
        }, [dispatch]),
        keyRef = useRef(),
        valueRef = useRef(),
        columnRef = useRef(),
        closeGroupView = useCallback(() => {
          batch(() => {
            dispatch(basicTimeseriesActions.onChange(false, "isEditingKey"))
            dispatch(keyTimeseriesActions.clear())
          })
        }, [dispatch]),
        onChangeKey = useCallback((val, { data }) => {
          setKey(val)
          setKeyType(data?.type)
        }, [])

  useEffect(() => {
    if(key && keyType && value) {
      dispatch(keyTimeseriesActions.updateObject(s => ReduxService.updateObject(s, { [key]: value }), keyType === tsKeysTypes.METADATA ? "metadatas" : "symbols"))
      setKey()
      setKeyType()
      setValue()
      keyRef.current?.dropdown?.current?.clearValue?.()
      valueRef.current?.dropdown?.current?.clearValue?.()
    }
  }, [value, key, dispatch, keyType])

  useEffect(() => {
    keyRef.current?.refreshOptions?.()
    valueRef.current?.refreshOptions?.()
    columnRef.current?.refreshOptions?.()
  }, [symbols, metadatas])

  useEffect(() => {
    valueRef.current?.refreshOptions?.()
  }, [key])

  useEffect(() => () => {
    keyTimeseriesActions.clear()
  }, [])

  useEffect(() => {
    columnRef.current?.dropdown?.current?.setValue(columns)
  }, [columns])

  return (
    <Fragment>
      <div className="ng-office-app__authed__content__body__item__creator">
        <div 
            className={classNames(
              "ng-office-app__authed__content__body__item__creator__title",
              { "ng-office-app__authed__content__body__item__creator__title--with-action": keysExist }
            )}
        >
          ADD DATA
          <When condition={keysExist}>
            {() => (
              <Button
                  onClick={closeGroupView}
                  size="small"
                  variant="text"
              >
                Cancel
              </Button>
            )}
          </When>
        </div>
        <div className="ng-office-app__authed__content__body__item__creator__row">
          <LoadMoreDropdown
              autoFocus
              defaultValue={groupName}
              hasMore={hasMoreGroups}
              loadOptions={searchGroups}
              menuPlacement="bottom"
              name="groupName"
              noError
              onChange={onChangeGroupName}
              placeholder="Choose group"
              variant="border"
          />
        </div>
        <div className="ng-office-app__authed__content__body__item__creator__row">
          <LoadMoreDropdown
              disabled={!groupName}
              hasMore={hasMoreSymbolKeys}
              key={'key'+groupName}
              loadOptions={searchSymbolKeys}
              menuPlacement="bottom"
              name="key"
              noError
              onChange={onChangeKey}
              placeholder="Choose key"
              ref={keyRef}
              variant="border"
          />
          <LoadMoreDropdown
              creatable
              disabled={!key || !groupName}
              hasMore={hasMoreSymbolValues}
              key={'value'+groupName}
              loadOptions={searchValues}
              menuPlacement="bottom"
              name="value"
              noError
              onChange={setValue}
              placeholder="Choose value"
              ref={valueRef}
              variant="border"
          />
        </div>
        <div 
            className="ng-office-app__authed__content__body__item__creator__row"
            style={{ marginBottom: 16, display: "block" }}
        >
          {Object.entries(symbols || {}).map(([key, value]) => (
            <Tag
                key={`SymbolKey${key}`}
                onClear={() => removeSymbolKey(key)}
            >
              <div className="ng-office-app__authed__content__body__item__creator__row__tag__key">
                {key}:
              </div>
              <div className="ng-office-app__authed__content__body__item__creator__row__tag__value">
                {value}
              </div>
            </Tag>
          ))}
          {Object.entries(metadatas || {}).map(([key, value]) => (
            <Tag
                key={`Metadata${key}`}
                onClear={() => removeMetadataKey(key)}
            >
              <div className="ng-office-app__authed__content__body__item__creator__row__tag__left">
                <div className="ng-office-app__authed__content__body__item__creator__row__tag__left__key">
                  {key}:
                </div>
                <div className="ng-office-app__authed__content__body__item__creator__row__tag__left__value">
                  {value}
                </div>
              </div>
              <div className="ng-office-app__authed__content__body__item__creator__row__tag__metadata">
                Metadata
              </div>
            </Tag>
          ))}
        </div>
        <div className="ng-office-app__authed__content__body__item__creator__title">
          SELECT COLUMNS
        </div>
        <div 
            className="ng-office-app__authed__content__body__item__creator__row"
            style={{ marginBottom: 16 }}
        >
          <LoadMoreDropdown
              defaultValue={columns}
              disabled={(Object.keys(symbols).length === 0 && Object.keys(metadatas).length === 0) || !groupName}
              hasMore={hasMoreColumns}
              loadOptions={searchColumns}
              menuPortalTarget={document.body}
              multi
              name="columns"
              noError
              onChange={onChangeRedux}
              placeholder="Columns"
              ref={columnRef}
              variant="border"
          />
        </div>
      </div>
      <Preview/>
    </Fragment>
  )
}

export default KeyView;