import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MenuTab, MenuTabs, Search, StorageIcon, WithTooltip } from 'basic-components';

import { basicTimeseriesActions } from 'actions/timeseries';
import { routing } from 'routing';
import { GAUtils } from 'utils';

import AddNewTimeSeries from './AddNewTimeSeries';
import LoadedTimeSeries from './LoadedTimeSeries';

const tooltipStyle = { maxWidth: "260px" }
const TimeSeries = () => {
  const dispatch = useDispatch(),
        { query, activeTab } = useSelector(state => state.timeseriesState.basicState),
        setQuery = useCallback(query => dispatch(basicTimeseriesActions.onChange(query, "query")), [dispatch]),
        setTab = useCallback(tab => menuRef.current?.setSelectedTab(tab), []),
        menuRef = useRef()

  useEffect(() => {
    if(query) {
      GAUtils.sendEvent({
        category: "search",
        action: "search_ts",
        label: "search"
      })
    }
  }, [query])

  useEffect(() => {
    GAUtils.sendEvent({
      category: "open",
      action: "open_ts",
      label: "open"
    })
    GAUtils.sendPageView(routing.TS.name)
  }, [])

  useEffect(() => {
    if(activeTab) {
      setTab(activeTab)
    }
  }, [activeTab, setTab])

  return (
    <div className="ng-office-app__authed__content ng-office-app__authed__content--data-prep">
      <div className="ng-office-app__authed__content__header">
        <WithTooltip
            data-place="right"
            extraStyle={tooltipStyle}
            text="Selected Time Series will be loaded starting from the active cell in the spreadsheet. Grab the border of the panel to resize it."
        >
          <div className="ng-office-app__authed__content__header__icon">
            <StorageIcon
                color="grey-dark"
                height={20}
                width={20}
            />
          </div>
        </WithTooltip>
        <div className="ng-office-app__authed__content__header__search">
          <Search
              defaultValue={query}
              onSearch={setQuery}
              placeholder="Search added...."
              size="large"
              variant="outline"
          />
        </div>
      </div>
      <div className="ng-office-app__authed__content__body">
        <MenuTabs ref={menuRef}>
          <MenuTab
              name="ADD NEW"
          >
            <AddNewTimeSeries/>
          </MenuTab>
          <MenuTab
              name="ACTIVE"
          >
            <LoadedTimeSeries setTab={setTab}/>
          </MenuTab>
        </MenuTabs>
      </div>
    </div>
  )
}

export default TimeSeries;