import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ClearTable as ClearTableIcon, Edit, Refresh, WithTooltip } from 'basic-components';
import { globalActions } from 'primary-components';

import { basicCurvesActions } from 'actions/curves';
import { ExcelUtils } from 'utils';

import './LoadedCurvesRow.scss';

const LoadedCurvesRow = ({ address, refreshCurve, parameters, setTab }) => {
  const dispatch = useDispatch(),
        refreshCurveLocal = useCallback(() => 
          refreshCurve({ serie: { parameters, address } })
            .then(() => dispatch(globalActions.getMessageStore()).success("Curve refreshed."))
        , [address, dispatch, parameters, refreshCurve]),
        goToAddress = useCallback(() => ExcelUtils.goToAddress(address), [address]),
        editCurve = useCallback(() => {
          dispatch(basicCurvesActions.editFormula({
            ...parameters,
            address
          }))
          setTab("ADD NEW")
        }, [address, dispatch, parameters, setTab]),
        clearTable = useCallback(() => ExcelUtils.clearTableWithFormulaAddress(address), [address])

  return (
    <div className="ng-office-app__authed__content__body__item__row ng-office-app__authed__content__body__item__row--loaded-curve">
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--group">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          Group:
        </div>
        <div className="ng-office-app__authed__content__body__item__row__cell__bottom">
          {parameters.groupName}
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--address">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          Cell Reference:
        </div>
        <div 
            className="ng-office-app__authed__content__body__item__row__cell__bottom"
            onClick={goToAddress}
        >        
          {address}
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--product">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          Product:
        </div>
        <div className="ng-office-app__authed__content__body__item__row__cell__bottom">        
          {parameters.product}
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--root">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          Root:
        </div>
        <div className="ng-office-app__authed__content__body__item__row__cell__bottom">        
          {parameters.root}
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--columns">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          Column:
        </div>
        <div className="ng-office-app__authed__content__body__item__row__cell__bottom">        
          {parameters.column}
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--icons">
        <WithTooltip
            data-place="left"
            text="Download latest data."
        >
          <div 
              className="ng-office-app__authed__content__body__item__row__cell__icon"
              onClick={refreshCurveLocal}
              style={{ marginRight: "16px" }}
          >
            <Refresh
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
        <WithTooltip
            data-place="left"
            text="Edit formula."
        >
          <div 
              className="ng-office-app__authed__content__body__item__row__cell__icon"
              onClick={editCurve}
              style={{ marginRight: "16px" }}
          >
            <Edit
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
        <WithTooltip
            data-place="left"
            text="Clear table and formula."
        >
          <div 
              className="ng-office-app__authed__content__body__item__row__cell__icon"
              onClick={clearTable}
          >
            <ClearTableIcon
                color="red-dark"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
      </div>
    </div>
  )
}
LoadedCurvesRow.defaultProps = {
  address: "",
  parameters: {}
}

LoadedCurvesRow.propTypes = {
  address: PropTypes.string,
  parameters: PropTypes.object,
  refreshCurve: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
}

export default LoadedCurvesRow;