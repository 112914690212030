import { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowDown, ArrowUp, DatePicker, Dropdown, Radio, Settings as SettingsIcon, TextField, TimeField } from 'basic-components';
import { dateBetweenTypes, formatTypes, ReduxService, TimeService, tsDataRanges, tsDataRangesObject, tsLastTypes } from 'dashboard-services';
import { DateFormatPicker } from 'primary-components';

import { listTimeseriesActions } from 'actions/timeseries';

import ColumnsOrder from './ColumnsOrder';
import DateOrder from './DateOrder';
import OutputType from './OutputType';

import { Else, If, Then, When } from 'react-if';

import classNames from 'classnames';
import './Settings.scss';

const SOURCE = { label: "Source", value: undefined, tooltip: "Use source data time zone" }
const timeZonesWithDefault = [SOURCE].concat(TimeService.TIME_ZONES.map(t => ReduxService.updateObject(t, { tooltipPosition: "top" })))
const Settings = forwardRef((_, ref) => {
  const dispatch = useDispatch(),
        { 
          timeZone,
          range,
          lastTypeAmount,
          lastType,
          from,
          fromTime,
          to,
          toTime,
          fromSelected,
          toSelected,
          dateFormat,
          formatType,
          ...params
        } = useSelector(state => state.timeseriesState.listState),
        [areOptionsExpanded, setAreOptionsExpanded] = useState(false),
        onChange = useCallback((val, { name }) => dispatch(listTimeseriesActions.onChange(val, name)), [dispatch]),
        fromTimeoutRef = useRef(),
        toTimeoutRef = useRef(),
        onChangeRadio = useCallback((value, { name }) => {
          onChange(value, { name })
          if(value === "date") {
            if(!from || from?.length < 10) {
              onChange(to, { name: "from" })
            } else if(!to || to?.length < 10) {
              onChange(to, { name: "to" })
            }
          }
        }, [from, onChange, to]),
        onChangeFrom = useCallback(val => {
          onChange(val, { name: "from" })
          if(toSelected === "date" && (!to || to?.length < 10)) {
            onChange(to, { name: "to" })
          }
        }, [onChange, to, toSelected]),
        onChangeTo = useCallback(val => {
          onChange(val, { name: "to" })
          if(fromSelected === "date" && (!from || from?.length < 10)) {
            onChange(val, { name: "from" })
          }
        }, [from, fromSelected, onChange]),
        onChangeFromTime = useCallback((value, { name }) => {
          clearTimeout(fromTimeoutRef.current)
          fromTimeoutRef.current = setTimeout(() => onChange(TimeService.getTimeWithSeconds(value), name), 500)
        }, [onChange]),
        onChangeToTime = useCallback((value, { name }) => {
          clearTimeout(toTimeoutRef.current)
          toTimeoutRef.current = setTimeout(() => onChange(TimeService.getTimeWithSeconds(value), name), 500)
        }, [onChange]),
        swapExpand = useCallback(() => setAreOptionsExpanded(a => !a), []),
        defaultDateRange = useMemo(() => tsDataRanges.find(d => d.value === range), [range]),
        defaultLastType = useMemo(() => tsLastTypes.find(t => t.value === lastType), [lastType]),
        defaultTimeZone = useMemo(() => timeZonesWithDefault.find(t => t.value === timeZone), [timeZone])

  useEffect(() => () => {
    clearTimeout(fromTimeoutRef.current)
    clearTimeout(toTimeoutRef.current)
  }, [])

  useImperativeHandle(ref, () => ({
    getParams: () => ({
      timeZone,
      range,
      from,
      to,
      fromSelected,
      toSelected,
      fromTime,
      toTime,
      lastType,
      lastTypeAmount,
      dateFormat,
      formatType, 
      ...params
    })
  }), [dateFormat, range, formatType, from, fromSelected, fromTime, lastType, lastTypeAmount, params, timeZone, to, toSelected, toTime])

  return (
    <Fragment>
      <div className={classNames(
        "ng-office-app__authed__content__body__item__settings",
        { "ng-office-app__authed__content__body__item__settings--is-expanded": areOptionsExpanded },
      )}>
        <div 
            className="ng-office-app__authed__content__body__item__settings__top"
            onClick={swapExpand}
        >
          <div className="ng-office-app__authed__content__body__item__settings__top__icon">
            <SettingsIcon
                color="grey-medium"
                height={16}
                width={16}
            />
          </div>
          <div className="ng-office-app__authed__content__body__item__settings__top__label">
            Settings
          </div>
          <div className="ng-office-app__authed__content__body__item__settings__top__arrow">
            <If condition={areOptionsExpanded}>
              <Then>
                <ArrowUp
                    color="blue-bright"
                    height={24}
                    width={24}
                />
              </Then>
              <Else>
                <ArrowDown
                    color="blue-bright"
                    height={24}
                    width={24}
                />
              </Else>
            </If>
          </div>
        </div>
        <div className="ng-office-app__authed__content__body__item__settings__bottom">
          <div className="ng-office-app__authed__content__body__item__settings__bottom__subtitle">
            TIME AND DATE
          </div>
          <div 
              className="ng-office-app__authed__content__body__item__settings__bottom__label"
          >
            Timezone
          </div>
          <div className="ng-office-app__authed__content__body__item__settings__bottom__value">
            <Dropdown
                defaultValue={defaultTimeZone}
                fixed
                menuPortalTarget={document.querySelector('.ng-office-app')}
                name="timeZone"
                noError
                onChange={onChange}
                options={timeZonesWithDefault}
                variant="border"
            />
          </div>
          <div 
              className="ng-office-app__authed__content__body__item__settings__bottom__label"
          >
            Date format
          </div>
          <div className="ng-office-app__authed__content__body__item__settings__bottom__value">
            <DateFormatPicker
                dateFormat={dateFormat}
                fixed
                menuPortalTarget={document.querySelector('.ng-office-app')}
                noError
                onChange={onChange}
                variant="border"
            />
          </div>
          <div className="ng-office-app__authed__content__body__item__settings__bottom__label">
            Date range
          </div>
          <div className="ng-office-app__authed__content__body__item__settings__bottom__value">
            <Dropdown
                defaultValue={defaultDateRange}
                fixed
                menuPortalTarget={document.querySelector('.ng-office-app')}
                name="range"
                noError
                onChange={onChange}
                options={tsDataRanges}
                variant="border"
            />
          </div>
          <When condition={range === tsDataRangesObject.last || range === tsDataRangesObject.next}>
            {() => (
              <div className="ng-office-app__authed__content__body__item__settings__bottom__row">
                <div className="ng-office-app__authed__content__body__item__settings__bottom__row__item">
                  <div className="ng-office-app__authed__content__body__item__settings__bottom__label">
                    Type
                  </div>
                  <div className="ng-office-app__authed__content__body__item__settings__bottom__value">
                    <Dropdown
                        defaultValue={defaultLastType}
                        fixed
                        menuPortalTarget={document.querySelector('.ng-office-app')}
                        name="lastType"
                        noError
                        onChange={onChange}
                        options={tsLastTypes}
                        variant="border"
                    />
                  </div>
                </div>
                <div className="ng-office-app__authed__content__body__item__settings__bottom__row__item">
                  <div className="ng-office-app__authed__content__body__item__settings__bottom__label">
                    Amount
                  </div>
                  <div className="ng-office-app__authed__content__body__item__settings__bottom__value">
                    <TextField
                        defaultValue={lastTypeAmount}
                        min={1}
                        name="lastTypeAmount"
                        noError
                        onChange={onChange}
                        type="number"
                        variant="border"
                    />
                  </div>
                </div>
              </div>
            )}
          </When>
          <When condition={range === tsDataRangesObject.between}>
            {() => (
              <Fragment>
                <div className="ng-office-app__authed__content__body__item__settings__bottom__row">
                  <div className="ng-office-app__authed__content__body__item__settings__bottom__label">From</div>
                  <div className="ng-office-app__authed__content__body__item__settings__bottom__pickers">
                    <Radio
                        checked={fromSelected === dateBetweenTypes.today}
                        groupName="fromSelected"
                        name={dateBetweenTypes.today}
                        onChange={onChangeRadio}
                    >
                      Today  
                    </Radio>
                    <div className="ng-office-app__authed__content__body__item__settings__bottom__pickers__dates">
                      <Radio checked={fromSelected === dateBetweenTypes.date}
                          groupName="fromSelected"
                          name={dateBetweenTypes.date}
                          onChange={onChangeRadio}
                      >
                        {""}
                      </Radio>
                      <DatePicker
                          defaultValue={from}
                          disabled={fromSelected !== dateBetweenTypes.date}
                          name="from"
                          noError
                          onChange={onChangeFrom}
                      />
                      <TimeField
                          defaultValue={fromTime}
                          disabled={fromSelected !== dateBetweenTypes.date}
                          name="fromTime"
                          noError
                          onChange={onChangeFromTime}
                          shouldValidateWithSeconds={false}
                          withSeconds
                      />
                    </div>
                  </div>
                </div>
                <div className="ng-office-app__authed__content__body__item__settings__bottom__row">
                  <div className="ng-office-app__authed__content__body__item__settings__bottom__label">To</div>
                  <div className="ng-office-app__authed__content__body__item__settings__bottom__pickers">
                    <Radio 
                        checked={toSelected === dateBetweenTypes.today}
                        groupName="toSelected"
                        name={dateBetweenTypes.today}
                        onChange={onChangeRadio}
                    >
                      Today  
                    </Radio>
                    <div className="ng-office-app__authed__content__body__item__settings__bottom__pickers__dates">
                      <Radio 
                          checked={toSelected === dateBetweenTypes.date}
                          groupName="toSelected"
                          name={dateBetweenTypes.date}
                          onChange={onChangeRadio}
                      >
                        {""}
                      </Radio>
                      <DatePicker
                          defaultValue={to}
                          disabled={toSelected !== dateBetweenTypes.date}
                          name="to"
                          noError
                          onChange={onChangeTo}
                      />
                      <TimeField
                          defaultValue={toTime}
                          disabled={toSelected !== dateBetweenTypes.date}
                          name="toTime"
                          noError
                          onChange={onChangeToTime}
                          shouldValidateWithSeconds={false}
                          withSeconds
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </When>
          <DateOrder/>
          <div className="ng-office-app__authed__content__body__item__settings__bottom__subtitle">
            OUTPUT CONFIGURATION
          </div>
          <OutputType/>
          <div className="ng-office-app__authed__content__body__item__settings__bottom__subtitle">
            DATA SORTING
          </div>
          <When condition={formatType === formatTypes.CSV}>
            {() => (
              <ColumnsOrder/>
            )}
          </When>
        </div>
      </div>
      <When condition={areOptionsExpanded}>
        {() => (
          <div 
              className="ng-office-app__authed__content__body__item__background"
              onClick={swapExpand}
          />
        )}
      </When>
    </Fragment>
  )
})

Settings.displayName = "Options"

export default Settings;