import PropTypes from 'prop-types';

import { Clear, Warning } from 'basic-components';

import properties from 'resources/constants/properties.json';
import { routing } from 'routing';

import './TooLongWarn.scss';

const DASHBOARD_URL = properties.baseUrl.dashboardUrl

const TooLongWarn = ({ onClose, tooLongParams }) => (
  <div className="too-long-warn">
    <div className="too-long-warn__left">
      <Warning
          bgColor="orange-base"
          color="white-base"
          height={16}
          width={16}
      />
    </div>
    <div className="too-long-warn__right">
      <p className="too-long-warn__right__subtitle">
        {!tooLongParams ? "Formula" : `Parameter${tooLongParams.length > 1 ? "s" : ""} ${tooLongParams.map(p => `'${p}'`).join(", ")}`} has exceeded maximum length. Formula has been copied to clipboard.
      </p>
      <a 
          className="too-long-warn__right__link"
          href={`${DASHBOARD_URL}${routing.EXTERNAL.documentation.url}`}
          target="_blank"
      >
        Need help? Open user guide
      </a>
      <div 
          className="too-long-warn__right__clear"
          onClick={onClose}
      >
        <Clear
            color="grey-dark"
            height={12}
            width={12}
        />
      </div>
    </div>
  </div>
)

TooLongWarn.defaultProps = {
  tooLongParams: undefined
}

TooLongWarn.propTypes = {
  onClose: PropTypes.func.isRequired,
  tooLongParams: PropTypes.array
}

export default TooLongWarn;