import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, getColor, Switch } from 'basic-components';
import { CSV_HEADERS_TYPES, formatTypes } from 'dashboard-services';

import { listTimeseriesActions } from 'actions/timeseries';
import { TimeSeriesUtils } from 'utils';

import MetadataLabel from './MetadataLabel';

import { When } from 'react-if';

const OutputType = () => {
  const dispatch = useDispatch(),
        callsRefs = useRef([]),
        { 
          formatType, 
          csvHeaders, 
          shouldTranspose = "false"
        } = useSelector(state => state.timeseriesState.listState),
        localformatTypesArray = useMemo(() => [
          {
            label: formatTypes.NCSV,
            value: formatTypes.NCSV,
          },
          {
            label: formatTypes.CSV,
            value: formatTypes.CSV
          }
        ], []),
        localFormatType = useMemo(() => localformatTypesArray.find(f => f.value === formatType), [formatType, localformatTypesArray]),
        onChange = useCallback((val, { name }) => dispatch(listTimeseriesActions.onChange(val, name)), [dispatch]),
        onChangeBool = useCallback((val, { name }) => onChange(String(val), { name }), [onChange]),
        onChangeHeader = useCallback((_, { name, data }) => dispatch(onChange(data.map(obj => ({ name: obj.value, type: obj.type })), { name })), [dispatch, onChange]),
        searchKeys = useCallback(input => 
          dispatch(listTimeseriesActions.getKeysForGroups({ input, callsRefs }))
            .then(response => TimeSeriesUtils.csvHeadersOptions.concat(response))
        , [dispatch]),
        defaultCsvHeaders = useMemo(() => csvHeaders?.map(header => {
            const defaultOne = TimeSeriesUtils.csvHeadersOptions.find(c => c.value === header.name)
            if(defaultOne) {
              return defaultOne
            } else {
              return {
                value: header.name, 
                label: header.type === CSV_HEADERS_TYPES.METADATA_KEY ? (<MetadataLabel name={header.name}/>) : header.name,
                color: header.type === CSV_HEADERS_TYPES.METADATA_KEY ? getColor("grey-dark") : undefined,
                type: header.type
              }
            }
          }), [csvHeaders])

  useEffect(() => () => {
    callsRefs.current.forEach(promiseObj => promiseObj?.cancel())
  }, [])

  return (
    <Fragment>
      <div className="ng-office-app__authed__content__body__item__settings__bottom__label">
        Output type
      </div>
      <div className="ng-office-app__authed__content__body__item__settings__bottom__value">
        <Dropdown
            defaultValue={localFormatType}
            fixed
            menuPortalTarget={document.querySelector('.ng-office-app')}
            name="formatType"
            noError
            onChange={onChange}
            options={localformatTypesArray}
            variant="border"
        />
      </div>
      <When condition={formatType === formatTypes.CSV}>
        {() => (
          <Fragment>
            <div className="ng-office-app__authed__content__body__item__settings__bottom__label">
              Column header
            </div>
            <div className="ng-office-app__authed__content__body__item__settings__bottom__value">
              <Dropdown
                  async
                  defaultValue={defaultCsvHeaders}
                  fixed
                  loadOptions={searchKeys}
                  menuPortalTarget={document.querySelector('.ng-office-app')}
                  multi
                  name="csvHeaders"
                  noError
                  onChange={onChangeHeader}
                  openMenuOnFocus
                  variant="border"
              />
            </div>
          </Fragment>
        )}
      </When>
      <div className="ng-office-app__authed__content__body__item__settings__bottom__switch">
        <div className="ng-office-app__authed__content__body__item__settings__bottom__label">
          Transpose the result
        </div>
        <div className="ng-office-app__authed__content__body__item__settings__bottom__value">
          <Switch
              name="shouldTranspose"
              noError
              on={shouldTranspose === "true"}
              onChange={onChangeBool}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default OutputType;