import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatTypes } from 'dashboard-services';

import { basicCurvesActions } from 'actions/curves';

import TsSettings, { OutputType, SetAsDefaultSettings, TimeAndDate } from 'primary/TsSettings';

const Settings = () => {
  const dispatch = useDispatch(),
        { 
          csvHeaders,
          timeZone,
          range,
          lastTypeAmount,
          lastType,
          from,
          fromTime,
          to,
          toTime,
          fromSelected,
          toSelected,
          dateFormat
        } = useSelector(state => state.curvesState.basicState),
        onSaveDefaultSettings = useCallback(() => dispatch(basicCurvesActions.saveDefaultSettings()), [dispatch]),
        onChange = useCallback((val, { name }) => dispatch(basicCurvesActions.onChange(val, name)), [dispatch]),
        getKeys = useCallback(({ input, callsRefs }) => dispatch(basicCurvesActions.getKeysForGroup({ input, callsRefs })), [dispatch])
  return (
    <TsSettings>
      <SetAsDefaultSettings onSave={onSaveDefaultSettings}/>
      <OutputType
          csvHeaders={csvHeaders}
          formatType={formatTypes.CSV}
          getKeys={getKeys}
          onChange={onChange}
          withOutputType={false}
          withTranspose={false}
      />
      <TimeAndDate
          dateFormat={dateFormat}
          from={from}
          fromSelected={fromSelected}
          fromTime={fromTime}
          lastType={lastType}
          lastTypeAmount={lastTypeAmount}
          onChange={onChange}
          range={range}
          timeZone={timeZone}
          to={to}
          toSelected={toSelected}
          toTime={toTime}
      />
    </TsSettings>
  )
}

export default Settings;