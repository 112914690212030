import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Copy, Edit as EditIcon, IconButton, Remove as RemoveIcon } from 'basic-components';
import { ReduxService } from 'dashboard-services';

import { keyTimeseriesActions, listTimeseriesActions } from 'actions/timeseries';

import Draggable from 'react-draggable';

import './KeyRow.scss';

const getTop = ({ draggingIndex, itemIndex, hoveredIndex, withDragAndDrop }) => {
  if(!withDragAndDrop || hoveredIndex === -1) {
    return 0
  }
  const elements = document.getElementsByClassName("ng-office-app__authed__content__body__item__creator__key-row--is-dragging") || []
  const height = (elements[0]?.clientHeight + 8) / 2
  let baseTop = 0
  if(draggingIndex === hoveredIndex) {
    return baseTop
  }
  if(itemIndex > draggingIndex && draggingIndex !== -1) {
    baseTop -= height
  } else if(itemIndex < draggingIndex && draggingIndex !== -1) {
    baseTop += height
  }
  if(hoveredIndex === -1 || draggingIndex === itemIndex) {
    return baseTop
  }
  if(hoveredIndex > itemIndex || (hoveredIndex === itemIndex && draggingIndex < itemIndex))  {
    return baseTop - height
  } else {
    return baseTop + height
  }
}

const getTransition = ({ draggingIndex, itemIndex }) => {
  if(draggingIndex !== -1 && itemIndex !== draggingIndex) {
    return "top .2s ease-in"
  } else {
    return "none"
  }
}

const prevSiblings = target => {
  const siblings = []
  let n = target;
  while(n.previousElementSibling) {
    siblings.push(n.previousElementSibling)
    n = n.previousElementSibling
  }
  return siblings;
}

const nextSiblings = target => {
  const siblings = []
  let n = target;
  while(n.nextElementSibling) {
    siblings.push(n.nextElementSibling)
    n = n.nextElementSibling
  }
  return siblings;
}

const getSiblingsAndCurrent = target => {
   const prev = prevSiblings(target) || [],
       next = nextSiblings(target) || [];
   return prev.concat([target]).concat(next);
}

const STARTING_POSITION = ({ y: 0, x: 0 })
const KeyRow = ({ index, symbols, metadatas, groupName, setHoveredIndex, setDraggingIndex, hoveredIndex, draggingIndex, onDragAndDrop }) => {
  const dispatch = useDispatch(),
        removeKey = useCallback(e => {
          e.preventDefault()
          e.stopPropagation()
          dispatch(listTimeseriesActions.removeKey(index))
        }, [dispatch, index]),
        duplicateKey = useCallback(e => {
          e.preventDefault()
          e.stopPropagation()
          dispatch(listTimeseriesActions.duplicateKey(index))
        }, [dispatch, index]),
        editKey = useCallback(e => {
          e.preventDefault()
          e.stopPropagation()
          dispatch(keyTimeseriesActions.setKeyToEdit(index))
        }, [dispatch, index]),
        symbol = useMemo(() => {
          const [key, value] = (Object.entries(symbols || {})[0]) || (Object.entries(metadatas || {})[0]) || []
          return { key, value }
        }, [metadatas, symbols]),
        elementRef = useRef(),
        childsArray = useRef(),
        getPositionOfElement = useCallback(element => ({ top: element?.offsetTop, bottom: element?.offsetTop + element?.offsetHeight + parseInt(window.getComputedStyle(element).marginTop) + + parseInt(window.getComputedStyle(element).marginBottom) }), []),
        onDrag = useCallback((_, data) => {
          const direction = Math.sign(data.deltaY)
          if(direction === 0) {
            return
          }
          const orgPosition = getPositionOfElement(elementRef.current)
          const currentPosition = ReduxService.updateObject(orgPosition, { top: orgPosition.top + data.y, bottom: orgPosition.bottom + data.y })
          setHoveredIndex(childsArray.current.findIndex(p => 
              (p.bottom >= currentPosition.top && p.top <= currentPosition.top && direction < 0) || 
              (currentPosition.bottom >= p.top && currentPosition.bottom <= p.bottom && direction > 0))
          )
        }, [getPositionOfElement, setHoveredIndex]),
        onDragStart = useCallback(() => {
          const children = getSiblingsAndCurrent(elementRef.current)
          const positionArrays = children.map(child => getPositionOfElement(child)).sort((a, b) => a.top - b.top)
          childsArray.current = positionArrays;
          setDraggingIndex(index)
          setHoveredIndex(index)
        }, [setDraggingIndex, index, setHoveredIndex, getPositionOfElement]),
        onDragStop = useCallback(e => {
          e.preventDefault()
          if(index === hoveredIndex) {
            return
          }
          onDragAndDrop({ from: index, to: hoveredIndex })
          setHoveredIndex(-1)
          setDraggingIndex(-1)
        }, [index, hoveredIndex, onDragAndDrop, setHoveredIndex, setDraggingIndex])

  return (
    <Draggable
        axis="y"
        bounds="parent"
        defaultClassNameDragging="ng-office-app__authed__content__body__item__creator__key-row--is-dragging"
        handle=".ng-office-app__authed__content__body__item__creator__key-row"
        onDrag={onDrag}
        onStart={onDragStart}
        onStop={onDragStop}
        position={STARTING_POSITION}
        scale={1}
        zIndex={1}
    >
      <div 
          className="ng-office-app__authed__content__body__item__creator__key-row"
          ref={elementRef}
          style={{ 
            top: getTop({ draggingIndex, itemIndex: index, hoveredIndex, withDragAndDrop: !!onDragAndDrop }),
            transition: getTransition({ draggingIndex, itemIndex: index })
          }}
      >
        <div className="rectangle"/>
        <div className="ng-office-app__authed__content__body__item__creator__key-row__cells">
          <div className="ng-office-app__authed__content__body__item__creator__key-row__cells__cell ng-office-app__authed__content__body__item__creator__key-row__cells__cell--title">
            <div className="ng-office-app__authed__content__body__item__creator__key-row__cells__cell__top">
              FILTER
            </div>
            <div className="ng-office-app__authed__content__body__item__creator__key-row__cells__cell__bottom">
              {groupName}
            </div>
          </div>
          <div className="ng-office-app__authed__content__body__item__creator__key-row__cells__cell ng-office-app__authed__content__body__item__creator__key-row__cells__cell--symbol">
            <div className="ng-office-app__authed__content__body__item__creator__key-row__cells__cell__top">
              {`${symbol.key}:`}
            </div>
            <div className="ng-office-app__authed__content__body__item__creator__key-row__cells__cell__bottom">
              {symbol.value}
            </div>
          </div>
        </div>
        <div className="ng-office-app__authed__content__body__item__creator__key-row__actions">
          <IconButton
              className="ng-office-app__authed__content__body__item__creator__key-row__actions__action"
              onClick={editKey}
          >
            <EditIcon 
                color="blue-bright"
                height={16}
                width={16}
            />
          </IconButton>
          <IconButton
              className="ng-office-app__authed__content__body__item__creator__key-row__actions__action"
              onClick={duplicateKey}
          >
            <Copy 
                color="blue-bright"
                height={16}
                width={16}
            />
          </IconButton>
          <IconButton
              className="ng-office-app__authed__content__body__item__creator__key-row__actions__action"
              onClick={removeKey}
          >
            <RemoveIcon 
                color="red-base"
                height={16}
                width={16}
            />
          </IconButton>
        </div>
      </div>
    </Draggable>
  )
}

KeyRow.defaultProps = {
  draggingIndex: undefined,
  hoveredIndex: undefined,
  metadatas: {},
  symbols: {}
}

KeyRow.propTypes = {
  draggingIndex: PropTypes.number,
  groupName: PropTypes.string.isRequired,
  hoveredIndex: PropTypes.number,
  index: PropTypes.number.isRequired,
  metadatas: PropTypes.object,
  onDragAndDrop: PropTypes.func.isRequired,
  setDraggingIndex: PropTypes.func.isRequired,
  setHoveredIndex: PropTypes.func.isRequired,
  symbols: PropTypes.object,
}

export default KeyRow