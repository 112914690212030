import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, FormRow, Label, LoadMoreDropdown, TextField } from 'basic-components';
import { DropdownService, HooksService } from 'dashboard-services';

import { basicCurvesActions } from 'actions/curves';
import getApiConfig from 'api/ApiConfig';

import { PreviewCurve } from 'components/App/Authed/TimeSeries/AddNewTimeSeries/Creator/KeyView/Preview';
import TooLongWarn from 'primary/TooLongWarn';
import Settings from './CurveSettings';

import { When } from 'react-if';

import './CurveCreator.scss';

const CurveCreator = () => {
  const dispatch = useDispatch(),
        isMounted = HooksService.useIsMounted(),
        { 
          groupName,
          product,
          root,
          column,
          symbolSize,
          showTooLongFormula,
        } = useSelector(state => state.curvesState.basicState),
        { searchGroups, hasMoreGroups } = DropdownService.useSearchGroups(dispatch(getApiConfig()), isMounted, { symbolsOnly: true, extraQuery: "&fields.name=Forward_Curve" }),
        onChangeRedux = useCallback((val, { name }) => dispatch(basicCurvesActions.onChange(val, name)), [dispatch]),
        onChangeColumn = useCallback(val => onChangeRedux(val, { name: "column" }), [onChangeRedux]),
        onChangeRoot = useCallback(val => {
          onChangeColumn(undefined)
          onChangeRedux(val, { name: "root" })
        }, [onChangeColumn, onChangeRedux]),
        onChangeProduct = useCallback(val => {
          onChangeRoot(undefined)
          onChangeRedux(val, { name: "product" })
        }, [onChangeRedux, onChangeRoot]),
        onChangeGroupName = useCallback(val => {
          onChangeProduct(undefined)
          onChangeRedux(val, { name: "groupName" })
        }, [onChangeProduct, onChangeRedux]),
        { hasMoreSymbolValues: hasMoreProducts, searchSymbolValues: searchProducts } = DropdownService.useSearchSymbolValues(dispatch(getApiConfig()), { 
          key: basicCurvesActions.PRODUCT,
          groupName,
          metadata: true,
          useEs: true
        }),
        rootMetadatas = useMemo(() => product ? { [basicCurvesActions.PRODUCT]: [product] } : {}, [product]),
        { hasMoreSymbolValues: hasMoreRoots, searchSymbolValues: searchRoots } = DropdownService.useSearchSymbolValues(dispatch(getApiConfig()), { 
          key: basicCurvesActions.ROOT,
          groupName,
          metadata: true,
          metadatas: rootMetadatas,
          useEs: true
        }),
        columnSymbols = useMemo(() => root ? { [basicCurvesActions.SYMBOL]: `${root}_0*` } : {}, [root]),
        columnMetadatas = useMemo(() => product && root ? { [basicCurvesActions.PRODUCT]: product, [basicCurvesActions.ROOT]: root } : {}, [product, root]),
        { hasMoreColumns, searchColumns } = DropdownService.useSearchColumns(dispatch(getApiConfig()), {
          symbols: columnSymbols,
          groupName,
          metadata: true,
          metadatas: columnMetadatas,
          useEs: true
        }),
        hideWarns = useCallback(() => {
          onChangeRedux(false, { name: "showTooLongFormula" })
        }, [onChangeRedux]),
        prevGroupName = HooksService.usePrevious(groupName),
        prevProduct = HooksService.usePrevious(product),
        prevRoot = HooksService.usePrevious(root),
        groupRef = useRef(),
        productRef = useRef(),
        rootRef = useRef(),
        columnRef = useRef()

  useEffect(() => {
    if(groupName !== prevGroupName) {
      productRef.current?.refreshOptions?.()
      prevGroupName && productRef?.current?.dropdown?.current?.setValue()
      !groupName && groupRef?.current?.dropdown?.current?.setValue()
      !product && productRef.current?.dropdown?.current?.focus?.()
    }
  }, [groupName, prevGroupName, product])

  useEffect(() => {
    if(product !== prevProduct) {
      rootRef.current?.refreshOptions?.()
      prevProduct && rootRef?.current?.dropdown?.current?.setValue()
      !root && rootRef.current?.dropdown?.current?.focus?.()
    }
  }, [prevProduct, product, root])

  useEffect(() => {
    if(root !== prevRoot) {
      columnRef.current?.refreshOptions?.()
      prevRoot && columnRef?.current?.dropdown?.current?.setValue()
      !column && columnRef.current?.dropdown?.current?.focus?.()
    }
  }, [column, prevRoot, root])

  return (
    <Fragment>
      <Settings/>
      <Form className="ng-office-app__authed__content__body__item__curve-creator">
        <div className="ng-office-app__authed__content__body__item__curve-creator__divider"/>
        <FormRow 
            className="ng-office-app__authed__content__body__item__curve-creator__row"
            isVertical
        >
          <Label>GROUP</Label>
          <LoadMoreDropdown
              autoFocus={!groupName}
              defaultValue={groupName}
              hasMore={hasMoreGroups}
              loadOptions={searchGroups}
              menuPlacement="bottom"
              menuPortalTarget={document.querySelector('.ng-office-app')}
              name="groupName"
              noError
              onChange={onChangeGroupName}
              openMenuOnFocus
              placeholder="Choose source group"
              ref={groupRef}
              variant="border"
          />
        </FormRow>
        <FormRow 
            className="ng-office-app__authed__content__body__item__curve-creator__row"
            isVertical
        >
          <Label>PRODUCT</Label>
          <LoadMoreDropdown
              defaultValue={product}
              disabled={!groupName}
              fixed
              hasMore={hasMoreProducts}
              loadOptions={searchProducts}
              menuPlacement="bottom"
              menuPortalTarget={document.querySelector('.ng-office-app')}
              name="product"
              noError
              onChange={onChangeProduct}
              openMenuOnFocus
              placeholder="Choose product"
              ref={productRef}
              variant="border"
          />
        </FormRow>
        <FormRow 
            className="ng-office-app__authed__content__body__item__curve-creator__row"
            isVertical
        >
          <Label>ROOT</Label>
          <LoadMoreDropdown
              defaultValue={root}
              disabled={!product || !groupName}
              fixed
              hasMore={hasMoreRoots}
              loadOptions={searchRoots}
              menuPlacement="bottom"
              menuPortalTarget={document.querySelector('.ng-office-app')}
              name="root"
              noError
              onChange={onChangeRoot}
              openMenuOnFocus
              placeholder="Choose root"
              ref={rootRef}
              variant="border"
          />
        </FormRow>
        <div className="ng-office-app__authed__content__body__item__curve-creator__divider"/>
        <FormRow 
            className="ng-office-app__authed__content__body__item__curve-creator__row"
            isVertical
        >
          <Label>COLUMN</Label>
          <LoadMoreDropdown
              defaultValue={column}
              disabled={!product || !root || !groupName}
              fixed
              hasMore={hasMoreColumns}
              loadOptions={searchColumns}
              menuPlacement="bottom"
              menuPortalTarget={document.querySelector('.ng-office-app')}
              name="column"
              noError
              onChange={onChangeColumn}
              openMenuOnFocus
              placeholder="Choose column"
              ref={columnRef}
              variant="border"
          />
        </FormRow>
        <FormRow 
            className="ng-office-app__authed__content__body__item__curve-creator__row"
            isVertical
        >
          <Label>CURVE TERM</Label>
          <TextField
              defaultValue={symbolSize}
              name="symbolSize"
              noError
              onChange={onChangeRedux}
              placeholder="All"
              type="number"
              variant="border"
          />
        </FormRow>
      </Form>
      <PreviewCurve/>
      <When condition={showTooLongFormula}>
        {() => (
          <TooLongWarn onClose={hideWarns}/>
        )}
      </When>
    </Fragment>
  )
}

export default CurveCreator;