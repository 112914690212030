import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { NoResults } from 'basic-components';
import { CommonService, HooksService } from 'dashboard-services';
import { globalActions } from 'primary-components';

import { ExcelUtils, GAUtils, TimeSeriesUtils } from 'utils';

import LoadedSeriesRow from './LoadedSeriesRow';

import { When } from 'react-if';

const { validateTable } = TimeSeriesUtils

const LoadedTimeSeries = ({ setTab }) => {
  const dispatch = useDispatch(),
        query = useSelector(state => state.timeseriesState.basicState.query),
        [series, setSeries] = useState(),
        items = useMemo(() => series?.filter(serie => query ? serie.parsedParameters.getGroupedParams().findIndex(g => g.groupName.toLowerCase().search(query.toLowerCase())) > -1 : true), [query, series]),
        refreshAListRef = useRef(),
      
        loadUserSeries = useCallback(({ noFetching = false } = {}) => {
          if(refreshAListRef.current) {
            return Promise.resolve()
          }
          refreshAListRef.current = true;
          const reqId = CommonService.generateUID()
          !noFetching && dispatch(globalActions.handleRequest({ id: reqId }))
          ExcelUtils.getTablesInSpreadSheets({ validateTable }).then(items => setSeries(items.filter(serie => serie !== undefined)))
            .catch(err => {
              dispatch(globalActions.getMessageStore()).error(String(err))
              console.error(String(err))
            })
            .finally(() => {
              refreshAListRef.current = false;
              !noFetching && dispatch(globalActions.handleRequest({ id: reqId }))
            })
        }, [dispatch]),
        refreshSerie = useCallback(({ serie }) => {
          GAUtils.sendEvent({
            category: "action",
            action: "refresh_ts",
            label: "refresh"
          })
          return Excel.run(context => 
            ExcelUtils
              .refreshFormulaWithAdress({ address: serie?.address, formula: TimeSeriesUtils.paramsToFormula(serie?.parameters), context })
              .catch(err => {
                dispatch(globalActions.getMessageStore()).error(String(err))
                console.error(String(err))
                return Promise.resolve()
              })
          )
        }, [dispatch])

  HooksService.useInterval(() => {
    loadUserSeries({ noFetching: true })
  }, 15000)
  

  useEffect(() => {
    loadUserSeries()
  }, [loadUserSeries])

  return (
    <Fragment>
      <div className="ng-office-app__authed__content__body__item ng-office-app__authed__content__body__item--loaded">
        <div 
            className="ng-office-app__authed__content__body__item__body ng-office-app__authed__content__body__item__body--loaded"
            style={{ marginTop: 0 }}
        >
          <When condition={items?.length === 0}>
            {() => (
              <div className="no-results-wrapper">
                <NoResults
                    optionalText={query ? "Try to search a diffrent keyword" : "Navigate to Add New tab to add data."}
                    title="No data has been found."
                />
              </div>
            )}
          </When>
          <When condition={items?.length > 0}>
            {() => (
              items?.map((item, index) => (
                <LoadedSeriesRow
                    address={item.address}
                    key={String(index) + String(item.address)}
                    parameters={item.parsedParameters}
                    refreshSerie={refreshSerie}
                    setTab={setTab}
                />
              ))
            )}
          </When>
        </div>
      </div>
    </Fragment>
  )
}

LoadedTimeSeries.propTypes = {
  setTab: PropTypes.func.isRequired
}

export default LoadedTimeSeries;