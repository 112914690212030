import { Fragment, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Info, Radio, RadioGroup, WithTooltip } from 'basic-components';
import { tsDataRangesObject, tsOrder } from 'dashboard-services';

import { listTimeseriesActions } from 'actions/timeseries';

const DateOrder = () => {
  const dispatch = useDispatch(),
        { order = tsOrder.DESC, range } = useSelector(state => state.timeseriesState.listState),
        onChange = useCallback(value => dispatch(listTimeseriesActions.onChange(value, 'order')), [dispatch])

  useEffect(() => {
    if(range === tsDataRangesObject.last || range === tsDataRangesObject.next) {
      onChange(tsOrder.DESC)
    }
  }, [onChange, range])

  return (
    <Fragment>
      <div 
          className="ng-office-app__authed__content__body__item__settings__bottom__label ng-office-app__authed__content__body__item__settings__bottom__label--with-info"
          style={{ marginTop: 12 }}
      >
        Date sort order
        <WithTooltip
            data-place="top"
            style="max-width: 200px"
            text={(
              <p>For Date range 'Last'<br/>the only available data<br/>sorting is descending.</p>
            )}
        >
          <div className="ng-office-app__authed__content__body__item__settings__bottom__label__info">
            <Info
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
      </div>
      <div className="ng-office-app__authed__content__body__item__settings__bottom__value ng-office-app__authed__content__body__item__settings__bottom__value--radio">
        <RadioGroup
            name="sort-order"
            onChange={onChange}
            selectedValue={order}
        >
          <Radio
              disabled={range === tsDataRangesObject.last || range === tsDataRangesObject.next}
              name={tsOrder.ASC}
          >
            Ascending
          </Radio>
          <Radio
              disabled={range === tsDataRangesObject.last || range === tsDataRangesObject.next}
              name={tsOrder.DESC}
          >
            Descending
          </Radio>
        </RadioGroup>
      </div>
    </Fragment>
  )
}

export default memo(DateOrder)