import { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Running } from 'basic-components';
import { globalActions } from 'primary-components';

import { basicTimeseriesActions, keyTimeseriesActions, listTimeseriesActions } from 'actions/timeseries';

import './Footer.scss';

const emptyFun = () => {}
const Footer = ({ addToSheet }) => {
  const dispatch = useDispatch(),
        keys = useSelector(state => state.timeseriesState.listState.keys),
        address = useSelector(state => state.timeseriesState.basicState.address),
        resetValues = useCallback(() => {
          dispatch(globalActions.getToastsStore()).warning("All changes will be discarded. Do you want to proceed?", "Reset changes",{
            withCancel: false,
            confirmText: "Reset",
            confirmAction: () => {
              batch(() => {
                dispatch(listTimeseriesActions.clear())
                dispatch(basicTimeseriesActions.clear())
                dispatch(keyTimeseriesActions.clear())
              })
            }
          })
        }, [dispatch])

  return (
    <div className="ng-office-app__authed__content__body__item__footer">
      <Button
          onClick={resetValues}
          size="small"
          variant="text"
      >
        <Running
            bgColor="white-base"
            color="blue-bright"
            height={16}
            width={16}
        />
        <strong>
          Reset
        </strong>
      </Button>
      <Button
          disabled={keys?.length === 0}
          onClick={keys?.length === 0 ? emptyFun : addToSheet}
      >
        {address ? "Edit formula" : "Add to sheet"}
      </Button>
    </div>
  )
}

Footer.propTypes = {
  addToSheet: PropTypes.func.isRequired
}

export default Footer