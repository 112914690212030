import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FilesIcon, MenuTab, MenuTabs, Search, WithTooltip } from 'basic-components';

import { basicDatalakeActions } from 'actions/datalake';
import { routing } from 'routing';
import { GAUtils } from 'utils';

import AddNewFile from './AddNewFile';
import LoadedFiles from './LoadedFiles';

const tooltipStyle = { maxWidth: "260px" }
const DataLake = () => {
  const dispatch = useDispatch(),
        query = useSelector(state => state.datalakeState.query),
        setQuery = useCallback(query => dispatch(basicDatalakeActions.onChange(query, "query")), [dispatch])

  useEffect(() => {
    if(query) {
      GAUtils.sendEvent({
        category: "search",
        action: "search_file",
        label: "search"
      })
    }
  }, [query])

  useEffect(() => {
    GAUtils.sendEvent({
      category: "open",
      action: "open_dl",
      label: "open"
    })
    GAUtils.sendPageView(routing.LAKE.name)
  }, [])

  return (
    <div className="ng-office-app__authed__content ng-office-app__authed__content--data-lake">
      <div className="ng-office-app__authed__content__header">
        <WithTooltip
            data-place="right"
            extraStyle={tooltipStyle}
            text="Selected CSV file will be loaded starting from the active cell in the spreadsheet. Grab the border of the panel to resize it."
        >
          <div className="ng-office-app__authed__content__header__icon">
            <FilesIcon
                color="grey-dark"
                height={20}
                width={20}
            />
          </div>
        </WithTooltip>
        <div className="ng-office-app__authed__content__header__search">
          <Search
              autoFocus={false}
              defaultValue={query}
              onSearch={setQuery}
              placeholder="Search by name...."
              size="large"
              variant="outline"
          />
        </div>
      </div>
      <div className="ng-office-app__authed__content__body">
        <MenuTabs>
          <MenuTab
              name="ADD NEW"
          >
            <AddNewFile/>
          </MenuTab>
          <MenuTab
              name="ACTIVE"
          >
            <LoadedFiles/>
          </MenuTab>
        </MenuTabs>
      </div>
    </div>
  )
}

export default DataLake;