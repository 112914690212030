import { connect } from 'react-redux';

import { LOG_LEVEL, LOGGER, LogsApi } from 'dashboard-services';
import { ErrorBoundary } from 'primary-components';

import getApiConfig from 'api/ApiConfig';
import properties from 'resources/constants/properties.json';
import { GAUtils } from 'utils';

const mapStateToProps = state => ({
  userName: state.settingsState.userName,
  version: properties.version,
  getApiConfig: state.primaryComponentsState.apiConfigState.getApiConfig
})
const mapDispatchToProps = dispatch => ({
  sendErrorLog: ({ message, userName }) => {
    if(properties.version === "LOCALHOST") {
      return Promise.resolve();
    }
    GAUtils.sendEvent({ category: "exception", "description": message, fatal: true, action: "excel_exception" })
    return new LogsApi(dispatch(getApiConfig()))
      .post({ message: "(ErrorBoundary)" + message, userName, logger: LOGGER.EXCEL, level: LOG_LEVEL.ERROR })
      .noFetching(true)
      .noErrorMessage(true)
      .build()
      .call()
  },
  accessGetApiConfig: getApiConfig => dispatch(getApiConfig())
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)