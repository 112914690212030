import { connect } from 'react-redux';

import { LogsApi } from 'dashboard-services';
import { ErrorBoundary } from 'primary-components';

import getApiConfig from 'api/ApiConfig';
import properties from 'resources/constants/properties.json';

import { GAUtils } from 'util';

const mapStateToProps = state => ({
  userName: state.settingsState.userName,
  version: properties.version,
  getApiConfig: state.primaryComponentsState.apiConfigState.getApiConfig
})
const mapDispatchToProps = dispatch => ({
  sendErrorLog: message => {
    GAUtils.sendEvent({ category: "exception", "description": message, fatal: true, action: "excel_exception" })
    return new LogsApi(dispatch(getApiConfig()))
      .post({ message })
      .noFetching(true)
      .noErrorMessage(true)
      .build()
      .call()
  },
  accessGetApiConfig: getApiConfig => dispatch(getApiConfig())
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)