import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Resize } from 'basic-components';
import { DataStorageApi, SymbolSearchQuery } from 'dashboard-services';

import getApiConfig from 'api/ApiConfig';

import { When } from 'react-if';

import classNames from 'classnames';
import './Preview.scss';

const objToArray = obj => Object.entries(obj || {}).map(([key, value]) => ({ key, value }))
const Preview = ({ groupName, symbols, metadatas, isEditingKey, query, onSubmit, shouldAllowSubmit, onClear }) => {
  const dispatch = useDispatch(),
        [isPreviewExpanded, setIsPreviewExpanded] = useState(false),
        [previewItems, setPreviewItems] = useState(),
        [previewTotalSize, setPreviewTotalSize] = useState(),
        previewRef = useRef(),
        getPreviewItems = useCallback(() => {
          if(!groupName && !query) {
            return Promise.resolve()
          }
          previewRef.current?.cancel?.()
          previewRef.current = 
            new DataStorageApi(dispatch(getApiConfig()))
              .searchSymbols(
                new SymbolSearchQuery.Builder()
                  .withGroupName(groupName)
                  .withSymbols(objToArray(symbols))
                  .withMetadatas(objToArray(metadatas))
                  .withQueryString(query ? `${query}${query.startsWith("\"") && query.endsWith("\"") ? "" : "*"}` : "")
                  .build()
                  .getQuery(),
                0,
                20,
              )
              .noFetching(true)
              .cancelable(true)
              .build()
              .call()

          return previewRef.current.promise
              .then(({ items, totalSize }) => {
                setPreviewItems(items)
                setPreviewTotalSize(totalSize)
              })
        }, [dispatch, groupName, metadatas, query, symbols]),
        expandPreviewRef = useRef()

  useEffect(() => () => {
    clearTimeout(expandPreviewRef.current)
    previewRef.current?.cancel?.()
  }, [])

  useEffect(() => {
    setPreviewItems()
    setPreviewTotalSize()
  }, [groupName])

  useEffect(() => {
    getPreviewItems()
  }, [getPreviewItems])

  useEffect(() => {
    clearTimeout(expandPreviewRef.current)
    if(query) {
      expandPreviewRef.current = setTimeout(() => setIsPreviewExpanded(true), 500)
    }
  }, [query])

  return (
    <div className={classNames(
      "ng-office-app__authed__content__body__item__preview",
      { "ng-office-app__authed__content__body__item__preview--is-expanded": isPreviewExpanded },
    )}>
      <div className="ng-office-app__authed__content__body__item__preview__box">
        <div className="ng-office-app__authed__content__body__item__preview__box__top">
          <div className="ng-office-app__authed__content__body__item__preview__box__top__left">
            <div 
                className="ng-office-app__authed__content__body__item__preview__box__top__left__icon"
                onClick={previewTotalSize ? () => setIsPreviewExpanded(p => !p) : () => {}}
            >
              <Resize
                  color={previewTotalSize ? "blue-bright" : "grey-medium"}
                  height={16}
                  width={16}
              />
            </div>
            <div className="ng-office-app__authed__content__body__item__preview__box__top__left__label">
              Results: <p>{previewTotalSize !== undefined ? previewTotalSize : ""}</p>
            </div>
          </div>
          <div className="ng-office-app__authed__content__body__item__preview__box__top__right">
            <When condition={!!onClear}>
              {() => (
                <Button
                    onClick={onClear}
                    size="small"
                    variant="text"
                >
                  Clear
                </Button>
              )}
            </When>
            <Button
                disabled={!(previewTotalSize && shouldAllowSubmit)}
                onClick={previewTotalSize && shouldAllowSubmit ? onSubmit : () => {}}
                size="small"
                variant={isEditingKey ? "secondary" : "primary"}
            >
              {isEditingKey ? "Edit data" : "Add data"}
            </Button>
          </div>
        </div>
        <div className="ng-office-app__authed__content__body__item__preview__box__bottom">
          <When condition={!previewItems}>
            {() => (
              <div className="ng-office-app__authed__content__body__item__preview__box__bottom__no-results">
                <p>Select options to search for matching results</p>
              </div>
            )}
          </When>
          {(previewItems || []).map((item, index) => (
            <div 
                className="ng-office-app__authed__content__body__item__preview__box__bottom__item"
                key={index}
            >
              {Object.entries(item.symbols || {}).map(([key, value]) => (
                <div 
                    className="ng-office-app__authed__content__body__item__preview__box__bottom__item__entry"
                    key={`Symbol${key}`}
                >
                  <div className="ng-office-app__authed__content__body__item__preview__box__bottom__item__entry__key">
                    {key}:
                  </div>
                  <div className="ng-office-app__authed__content__body__item__preview__box__bottom__item__entry__value">
                    {value}
                  </div>
                </div>
              ))}
              {Object.entries(item.metadata || {}).map(([key, value]) => (
                <div 
                    className="ng-office-app__authed__content__body__item__preview__box__bottom__item__entry"
                    key={`Metadata${key}`}
                >
                  <div className="ng-office-app__authed__content__body__item__preview__box__bottom__item__entry__key">
                    {key}:
                  </div>
                  <div className="ng-office-app__authed__content__body__item__preview__box__bottom__item__entry__value ng-office-app__authed__content__body__item__preview__box__bottom__item__entry__value--is-metadata">
                    {value}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div 
          className="ng-office-app__authed__content__body__item__preview__cancel-bg"
          onClick={() => setIsPreviewExpanded(false)}
      />
    </div>
  )
}

Preview.defaultProps = {
  groupName: undefined,
  isEditingKey: false,
  metadatas: undefined,
  query: undefined,
  onClear: undefined,
  shouldAllowSubmit: false,
  symbols: undefined,
}

Preview.propTypes = {
  groupName: PropTypes.string, 
  isEditingKey: PropTypes.bool, 
  metadatas: PropTypes.object, 
  onClear: PropTypes.func,
  onSubmit: PropTypes.func.isRequired, 
  query: PropTypes.string, 
  shouldAllowSubmit: PropTypes.bool,
  symbols: PropTypes.object,
}

export default Preview;