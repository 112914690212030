import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CurvesIcon, MenuTab, MenuTabs, Search, WithTooltip } from 'basic-components';

import { basicCurvesActions } from 'actions/curves';
import { routing } from 'routing';
import { GAUtils } from 'utils';

import AddNewCurve from './AddNewCurve';
import LoadedCurves from './LoadedCurves';

const tooltipStyle = { maxWidth: "260px" }
const Curves = () => {
  const dispatch = useDispatch(),
        { query, activeTab } = useSelector(state => state.timeseriesState.basicState),
        setQuery = useCallback(query => dispatch(basicCurvesActions.onChange(query, "query")), [dispatch]),
        clearQuery = useCallback(() => setQuery(undefined), [setQuery]),
        setTab = useCallback(tab => {
          clearQuery()
          menuRef.current?.setSelectedTab(tab)
        }, [clearQuery]),
        menuRef = useRef()

  useEffect(() => {
    if(query) {
      GAUtils.sendEvent({
        category: "search",
        action: "search_curves",
        label: "search"
      })
    }
  }, [query])

  useEffect(() => {
    GAUtils.sendEvent({
      category: "open",
      action: "open_curves",
      label: "open"
    })
    GAUtils.sendPageView(routing.CURVES.name)
  }, [])

  useEffect(() => {
    if(activeTab) {
      setTab(activeTab)
    }
  }, [activeTab, setTab])

  return (
    <div className="ng-office-app__authed__content ng-office-app__authed__content--curves">
      <div className="ng-office-app__authed__content__header">
        <WithTooltip
            data-place="right"
            extraStyle={tooltipStyle}
            text="Selected Curve will be loaded starting from the active cell in the spreadsheet. Grab the border of the panel to resize it."
        >
          <div className="ng-office-app__authed__content__header__icon">
            <CurvesIcon
                color="grey-dark"
                height={20}
                width={20}
            />
          </div>
        </WithTooltip>
        <div className="ng-office-app__authed__content__header__search">
          <Search
              autoFocus={false}
              defaultValue={query}
              onSearch={setQuery}
              placeholder={activeTab === "ADD NEW" ? "Search..." : "Search added...."}
              size="large"
              variant="outline"
          />
        </div>
      </div>
      <div className="ng-office-app__authed__content__body">
        <MenuTabs 
            onChange={clearQuery}
            ref={menuRef}
        >
          <MenuTab
              name="ADD NEW"
          >
            <AddNewCurve/>
          </MenuTab>
          <MenuTab
              name="ACTIVE"
          >
            <LoadedCurves setTab={setTab}/>
          </MenuTab>
        </MenuTabs>
      </div>
    </div>
  )
}

export default Curves;