import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { basicTimeseriesActions, keyTimeseriesActions, listTimeseriesActions } from 'actions/timeseries';

import Creator from './Creator';

import './AddNewTimeSeries.scss';

const AddNewTimeSeries = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(basicTimeseriesActions.clear())
    dispatch(keyTimeseriesActions.clear())
    dispatch(listTimeseriesActions.clear())
  }, [dispatch])

  return (
    <div className="ng-office-app__authed__content__body__item ng-office-app__authed__content__body__item--add-serie">
      <Creator/>
    </div>
  )
}

export default AddNewTimeSeries;