import PropTypes from 'prop-types';

import { Clear, Warning } from 'basic-components';

import properties from 'resources/constants/properties.json';
import { routing } from 'routing';

import './TooLongWarn.scss';

const DASHBOARD_URL = properties.baseUrl.dashboardUrl

const TooLongWarn = ({ onClose }) => (
  <div className="too-long-warn">
    <div className="too-long-warn__top">
      <div className="too-long-warn__top__left">
        <Warning
            bgColor="orange-base"
            color="white-base"
            height={16}
            width={16}
        />
      </div>
      <div className="too-long-warn__top__right">
        <p className="too-long-warn__top__right__subtitle">
          Formula has exceeded maximum length. Formula has been copied to clipboard.
        </p>
        <a 
            className="too-long-warn__top__right__link"
            href={`${DASHBOARD_URL}${routing.EXTERNAL.documentation.url}`}
            target="_blank"
        >
          Need help? Open user guide
        </a>
        <div 
            className="too-long-warn__top__right__clear"
            onClick={onClose}
        >
          <Clear
              color="grey-dark"
              height={12}
              width={12}
          />
        </div>
      </div>
    </div>
  </div>
)

TooLongWarn.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default TooLongWarn;