import { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Container, Store } from 'basic-components';
import { HooksService, ReduxService } from 'dashboard-services';
import { apiConfigActions, Fetching, globalActions, loginActions } from 'primary-components';

import { basicSettingsActions } from 'actions/settings';
import { basicTimeseriesActions, listTimeseriesActions } from 'actions/timeseries';
import getApiConfig from 'api/ApiConfig';
import { routing } from 'routing';
import { AuthSingleton, GAUtils, TimeSeriesUtils } from 'utils';

import Authed from './Authed';
import DataLake from './Authed/DataLake';
import TimeSeries from './Authed/TimeSeries';
import Upload from './Authed/Upload';
import ErrorBoundary from './ErrorBoundary';
import LoginPageWrapper from './LoginPageWrapper';
import Settings from './Settings';

import { Case, Default, Else, If, Switch, Then } from 'react-if';

import './App.scss';

const DEFAULT_COMPONENT = routing.LAKE.name
const App = () => {
  const dispatch = useDispatch(),
        [isDone, setIsDone] = useState(false),
        [activeComponent, setActiveComponent] = useState(DEFAULT_COMPONENT),
        [isTaskPaneVisible, setIsTaskPaneVisible] = useState(true),
        { company, userName } = useSelector(state => state.settingsState),
        clickedAddress = useRef(),
        // prevUserName = HooksService.usePrevious(userName),
        token = useSelector(state => state.primaryComponentsState.loginState.token),
        messageStore = useSelector(state => state.globalState.MessageStore),
        toastsStore = useSelector(state => state.globalState.ToastsStore),
        isFetching = useSelector(state => state.globalState.fetchingRequests.filter(f => !f.noFetching).length > 0),
        tokenExist = token != null && typeof token != "undefined" && token !== "",
        width = HooksService.useWindowSize()?.width,
        onWindowResize = useCallback(() => {
          if(width < 612){
            dispatch(globalActions.onChange(true, "isMobile"))
          } else {
            dispatch(globalActions.onChange(false, "isMobile"))
          }
        }, [dispatch, width]),
        handleIdChange = useCallback(() => {
          const id = document.body.id;
          setActiveComponent(id || DEFAULT_COMPONENT);
        }, []),
        loadSettings = useCallback(() => {
          dispatch(basicSettingsActions.getSettings(token))
            .catch(() => {
              dispatch(loginActions.logOut(false))
            })
        }, [dispatch, token]),
        eventFormulaRef = useRef(),
        onDoubleClick = useCallback(({ address, formula }) => {
          const tsParsed = TimeSeriesUtils.validateTable({
            cell: { address },
            formula,
            returnItem: true
          })
          if(tsParsed) {
            const groupedParams = tsParsed.parsedParameters.getGroupedParams()
            batch(() => {
              dispatch(listTimeseriesActions.editFormula({ 
                address: tsParsed.address, 
                keys: groupedParams.map(gp => ReduxService.updateObject(gp, { columns: gp.columns?.split(",") })),
                params: tsParsed.parsedParameters
              }))
              dispatch(basicTimeseriesActions.onChange("ADD NEW", "activeTab"))
            })
          }
        }, [dispatch]),
        doubleClickTiemoutRef = useRef(),
        handleOnSingleClicked = useCallback(e => {
          clearTimeout(doubleClickTiemoutRef.current)
          if(clickedAddress.current?.address === e.address) {
            onDoubleClick({ address: e.address, formula: clickedAddress.current.formula })
            clickedAddress.current = undefined;
          } else {
            Excel?.run(async context => {
              const cell = context.workbook.getActiveCell();
              cell.load("formulas")
              await context.sync()
              const formula = cell.formulas?.[0]?.[0]
              clickedAddress.current = {
                address: e.address,
                formula
              }
              doubleClickTiemoutRef.current = setTimeout(() => {
                clickedAddress.current = undefined;
              }, 500) // default double click max for Windows
            }).catch(e => {
              console.error(e)
            })
          }
        }, [onDoubleClick]),
        onTakeMeBack = useCallback(() => {
          Office.addin.hide()
          location.reload()
          setTimeout(() => {
            Office.addin.showAsTaskpane()
          }, 500)
        }, []),
        observer = useRef()

  useLayoutEffect(() => {
    observer.current = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'id') {
          handleIdChange();
        }
      })
    });
    observer.current.observe(document.body, { attributes: true })
  }, [handleIdChange])

  useEffect(() => {
    handleIdChange()
  }, [handleIdChange])

  useLayoutEffect(() => {
    dispatch(loginActions.logoutAllTabs(false))
    dispatch(apiConfigActions.changeApiConfig(getApiConfig))
    AuthSingleton.getInstance().refreshNGToken.finally(() => {
      setIsDone(true)
    })
    .catch(() => {})
  }, [dispatch])

  useEffect(() => {
    if(!messageStore) {
      dispatch(globalActions.onChange(new Store(), "MessageStore"))
    }
    if(!toastsStore) {
      dispatch(globalActions.onChange(new Store(), "ToastsStore"))
    }
  }, [dispatch, messageStore, toastsStore])

  useEffect(() => {
    onWindowResize()
  }, [width, onWindowResize])

  useEffect(() => {
    Office?.onReady(() => {
      Office?.addin?.onVisibilityModeChanged(function(args) {
        if (args.visibilityMode === "Taskpane") {
          setIsTaskPaneVisible(true)
        } else {
          setIsTaskPaneVisible(false)
        }
      })
    })
  }, [])

  useEffect(() => {
    token && !userName && loadSettings()
  }, [loadSettings, token, userName])

  useEffect(() => {
    if(userName) {
      GAUtils.init({ userName, company })
    }
  }, [company, dispatch, userName])

  // useEffect(() => {
  //   if(userName && prevUserName !== userName) {
  //     setTimeout(() => AuthUtils.disableLogInButton(), 10)
  //   } else if(!userName && prevUserName !== userName) {
  //     AuthUtils.enableLogInButton()
  //   }
  // }, [prevUserName, userName])

  useEffect(() => {
    Excel?.run(async context => {
      const worksheets = context.workbook.worksheets
      eventFormulaRef.current = worksheets.onSingleClicked.add(handleOnSingleClicked)
      context.sync()
    })
  }, [handleOnSingleClicked])

  useEffect(() => () => {
    observer.current.disconnect()
    Excel.run(eventFormulaRef.current?.context, async context => {
      eventFormulaRef.current?.remove()
      await context.sync();
    });
  }, [])

  return (
    <ErrorBoundary 
        onTakeMeBack={onTakeMeBack}
        shouldDisplayError={width === 0 || !isTaskPaneVisible}
    >
      <div className="ng-office-app">
        {toastsStore && (
          <Container
              globalTimeout={30000}
              position="top-right"
              store={toastsStore}
              variant="toasts"
          />
        )}
        {messageStore && (
          <Container
              globalTimeout={5000}
              position="top-center"
              store={messageStore}
              variant="message"
          />
        )}
        <If condition={!isDone}>
          <Then>
            {() => <Fetching isFetching/>}  
          </Then>
          <Else>
            {() => (
              <Fragment>
                <Fetching
                    isFetching={isFetching}
                />
                <If condition={activeComponent === routing.SETTINGS.name}>
                  <Then>
                    {() => (
                      <Settings isLoggedIn={tokenExist}/>
                    )}
                  </Then>
                  <Else>
                    {() => (
                      <Fragment>
                        <If condition={tokenExist}>
                          <Then>
                            {() => (
                              <Authed>
                                <Switch>
                                  <Case condition={activeComponent === routing.LAKE.name}>
                                    {() => (
                                      <DataLake/>
                                    )}
                                  </Case>
                                  <Case condition={activeComponent === routing.TS.name}>
                                    {() => (
                                      <TimeSeries/>
                                    )}
                                  </Case>
                                  <Case condition={activeComponent === routing.UPLOAD.name}>
                                    {() => (
                                      <Upload/>
                                    )}
                                  </Case>
                                  <Default>
                                    {() => (
                                      <TimeSeries/>
                                    )}
                                  </Default>
                                </Switch>
                              </Authed>
                            )}
                          </Then>
                          <Else>
                            {() => (
                              <LoginPageWrapper
                                  activeComponent={activeComponent}
                              />
                            )}
                          </Else>
                        </If>
                      </Fragment>
                    )}
                  </Else>
                </If>
              </Fragment>
            )}
          </Else>
        </If>
      </div>
    </ErrorBoundary>
  )
}

export default App;