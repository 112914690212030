import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { NoResults } from 'basic-components';
import { CommonService, HooksService } from 'dashboard-services';
import { globalActions } from 'primary-components';

import { AuthUtils, CurvesUtils, ExcelUtils, GAUtils } from 'utils';

import LoadedCurvesRow from './LoadedCurvesRow';

import { When } from 'react-if';

const { validateTable } = CurvesUtils

const LoadedCurves = ({ setTab }) => {
  const dispatch = useDispatch(),
        query = useSelector(state => state.curvesState.basicState.query),
        [curves, setCurves] = useState(),
        items = useMemo(() => curves?.filter(curve => {
          if(query) {
            const parsedParameters = curve.parsedParameters,
                  parsedQuery = query.toLowerCase()
            return String(parsedParameters.root).toLowerCase().search(parsedQuery) > -1 || String(parsedParameters.product).toLowerCase().search(parsedQuery) > -1
          }
          return true;
        }), [query, curves]),
        refreshAListRef = useRef(),
      
        loadUserCurves = useCallback(({ noFetching = false } = {}) => {
          if(refreshAListRef.current) {
            return Promise.resolve()
          }
          refreshAListRef.current = true;
          const reqId = CommonService.generateUID()
          !noFetching && dispatch(globalActions.handleRequest({ id: reqId }))
          ExcelUtils.getTablesInSpreadSheets({ validateTable }).then(items => setCurves(items.filter(curve => curve !== undefined)))
            .catch(err => {
              dispatch(globalActions.getMessageStore()).error(String(err))
              console.error(String(err))
              dispatch(AuthUtils.sendError({ error: "(LoadUserCurves)" + err.stack }))
            })
            .finally(() => {
              refreshAListRef.current = false;
              !noFetching && dispatch(globalActions.handleRequest({ id: reqId, isResponse: true }))
            })
        }, [dispatch]),
        refreshCurve = useCallback(({ serie }) => {
          GAUtils.sendEvent({
            category: "action",
            action: "refresh_curves",
            label: "refresh"
          })
          return Excel.run(context => 
            ExcelUtils
              .refreshFormulaWithAdress({ address: serie?.address, formula: CurvesUtils.paramsToFormula(serie?.parameters), context })
              .catch(err => {
                dispatch(AuthUtils.sendError({ error: "(RefreshCurves)" + err.stack }))
                dispatch(globalActions.getMessageStore()).error(String(err))
                console.error(String(err))
                return Promise.resolve()
              })
          )
        }, [dispatch])

  HooksService.useInterval(() => {
    loadUserCurves({ noFetching: true })
  }, 15000)

  useEffect(() => {
    loadUserCurves()
  }, [loadUserCurves])

  return (
    <Fragment>
      <div className="ng-office-app__authed__content__body__item ng-office-app__authed__content__body__item--loaded">
        <div 
            className="ng-office-app__authed__content__body__item__body ng-office-app__authed__content__body__item__body--loaded"
            style={{ marginTop: 0 }}
        >
          <When condition={items?.length === 0}>
            {() => (
              <div className="no-results-wrapper">
                <NoResults
                    optionalText={query ? "Try to search a diffrent keyword" : "Navigate to Add New tab to add data."}
                    title="No data has been found."
                />
              </div>
            )}
          </When>
          <When condition={items?.length > 0}>
            {() => (
              items?.map((item, index) => (
                <LoadedCurvesRow
                    address={item.address}
                    key={String(index) + String(item.address)}
                    parameters={item.parsedParameters}
                    refreshCurve={refreshCurve}
                    setTab={setTab}
                />
              ))
            )}
          </When>
        </div>
      </div>
    </Fragment>
  )
}

LoadedCurves.propTypes = {
  setTab: PropTypes.func.isRequired
}

export default LoadedCurves;