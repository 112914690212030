import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ClearTable as ClearTableIcon, Edit, Refresh, WithTooltip } from 'basic-components';
import { ReduxService } from 'dashboard-services';
import { globalActions } from 'primary-components';

import { listTimeseriesActions } from 'actions/timeseries';
import { ExcelUtils } from 'utils';

import './LoadedSeriesRow.scss';

const LoadedSeriesRow = ({ address, refreshSerie, parameters, setTab }) => {
  const dispatch = useDispatch(),
        refreshSerieLocal = useCallback(() => 
          refreshSerie({ serie: { parameters, address } })
            .then(() => dispatch(globalActions.getMessageStore()).success("Time Series refreshed."))
        , [address, dispatch, parameters, refreshSerie]),
        goToAddress = useCallback(() => ExcelUtils.goToAddress(address), [address]),
        editSerie = useCallback(() => {
          const groupedParams = parameters.getGroupedParams()
          dispatch(listTimeseriesActions.editFormula({
            params: parameters,
            keys: groupedParams.map(gp => ReduxService.updateObject(gp, { columns: gp.columns?.split(",") })),
            address
          }))
          setTab("ADD NEW")
        }, [address, dispatch, parameters, setTab]),
        groupNames = useMemo(() => parameters.getGroupedParams().map(i => i.groupName).toUnique(), [parameters]),
        symbols = useMemo(() => parameters.getGroupedParams().flatMap(i => Object.entries(i.symbols || {})), [parameters]),
        metadatas = useMemo(() => parameters.getGroupedParams().flatMap(i => Object.entries(i.metadatas || {})), [parameters]),
        values = useMemo(() => parameters.getGroupedParams().flatMap(i => i.columns?.split(","))?.toUnique(), [parameters]),
        clearTable = useCallback(() => ExcelUtils.clearTableWithFormulaAddress(address), [address])

  return (
    <div className="ng-office-app__authed__content__body__item__row ng-office-app__authed__content__body__item__row--loaded-serie">
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--group">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          {`Group${groupNames.length > 1 ? "s" : ""}:`}
        </div>
        <div className="ng-office-app__authed__content__body__item__row__cell__bottom">
          {groupNames.join(", ")}
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--address">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          Cell Reference:
        </div>
        <div 
            className="ng-office-app__authed__content__body__item__row__cell__bottom"
            onClick={goToAddress}
        >        
          {address}
        </div>
      </div>
      {symbols.map(([key, value], index) => (
        <div 
            className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--symbol"
            key={key + value + index}
        >
          <div className="ng-office-app__authed__content__body__item__row__cell__top">
            {key}:
          </div>
          <div className="ng-office-app__authed__content__body__item__row__cell__bottom">        
            {value}
          </div>
        </div>
      ))}
      {metadatas.map(([key, value], index) => (
        <div 
            className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--symbol"
            key={`Metadata${key}${value}${index}`}
        >
          <div className="ng-office-app__authed__content__body__item__row__cell__top">
            {key}:
          </div>
          <div className="ng-office-app__authed__content__body__item__row__cell__bottom">        
            {value}
          </div>
        </div>
      ))}
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--columns">
        <div className="ng-office-app__authed__content__body__item__row__cell__top">
          Columns:
        </div>
        <div className="ng-office-app__authed__content__body__item__row__cell__bottom">        
          {values.join(", ")}
        </div>
      </div>
      <div className="ng-office-app__authed__content__body__item__row__cell ng-office-app__authed__content__body__item__row__cell--icons">
        <WithTooltip
            data-place="left"
            text="Download latest data."
        >
          <div 
              className="ng-office-app__authed__content__body__item__row__cell__icon"
              onClick={refreshSerieLocal}
              style={{ marginRight: "16px" }}
          >
            <Refresh
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
        <WithTooltip
            data-place="left"
            text="Edit formula."
        >
          <div 
              className="ng-office-app__authed__content__body__item__row__cell__icon"
              onClick={editSerie}
              style={{ marginRight: "16px" }}
          >
            <Edit
                color="blue-bright"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
        <WithTooltip
            data-place="left"
            text="Clear table and formula."
        >
          <div 
              className="ng-office-app__authed__content__body__item__row__cell__icon"
              onClick={clearTable}
          >
            <ClearTableIcon
                color="red-dark"
                height={16}
                width={16}
            />
          </div>
        </WithTooltip>
      </div>
    </div>
  )
}
LoadedSeriesRow.defaultProps = {
  address: "",
  parameters: {}
}

LoadedSeriesRow.propTypes = {
  address: PropTypes.string,
  parameters: PropTypes.object,
  refreshSerie: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
}

export default LoadedSeriesRow;