import { connect } from 'react-redux';

import { listTimeseriesActions } from 'actions/timeseries';

import Preview from './Preview.js';

const mapStateToProps = state => {
  const keyState = state.timeseriesState.keyState,
        basicState = state.timeseriesState.basicState
  return {
    groupName: keyState.groupName,
    symbols: keyState.symbols,
    metadatas: keyState.metadatas,
    isEditingKey: keyState.index !== undefined,
    query: basicState.query,
    shouldAllowSubmit: !!keyState.groupName && (Object.keys(keyState.symbols).length > 0 || Object.keys(keyState.metadatas).length > 0) && keyState.columns?.length > 0
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: () => dispatch(listTimeseriesActions.submitKeyChanges())
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview)