import { useSelector } from 'react-redux';

import KeyView from './KeyView';
import ListView from './ListView';

import { Else, If, Then } from 'react-if';

import './Creator.scss';

const Creator = () => {
  const isEditingKey = useSelector(state => state.timeseriesState.basicState.isEditingKey)

  return(
    <If condition={isEditingKey}>
      <Then>
        {() => (
          <KeyView/>
        )}
      </Then>
      <Else>
        {() => (
          <ListView/>
        )}
      </Else>
    </If>
  )

}

export default Creator;