import { useCallback, useEffect, useMemo, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Button, Upload as UploadIcon } from 'basic-components';
import { CommonService, statusType } from 'dashboard-services';
import { StatusesBox } from 'primary-components';

import { basicUploadActions, fileUploadActions } from 'actions/upload';
import properties from 'resources/constants/properties.json';
import { routing } from 'routing';
import { GAUtils } from 'utils';

import HeaderNoSearch from 'primary/HeaderNoSearch';
import DefineFile from './DefineFile';
import FileConfig from './FileConfig';

import Papa from 'papaparse';
import { When } from 'react-if';

import './Upload.scss';

const EXTENSION = ".csv"
const DATALAKE = "Datalake"
const DASHBOARD_URL = properties.baseUrl.dashboardUrl
const Upload = () => {
  const dispatch = useDispatch(),
        {
          errors,
          groupName,
          fileName,
          fileType,
          sheetName,
          firstCell,
          lastCell,
          fields,
          jobIds, 
          areStatusesHidden
        } = useSelector(state => state.uploadState),  
        fileNameRef = useRef(),
        groupNameRef = useRef(),
        fileTypeRef = useRef(),
        setHidden = useCallback(value => dispatch(basicUploadActions.onChange(value, 'areStatusesHidden')), [dispatch]),
        clearJobIds = useCallback(() => dispatch(basicUploadActions.onChange([], 'jobIds')), [dispatch]),
        onChangeRaw = useCallback((value, { name }) => dispatch(basicUploadActions.onChange(value, name)), [dispatch]),
        isFilledOut = useMemo(() => groupName && fileName && fileType && sheetName && firstCell && lastCell, [fileName, fileType, firstCell, groupName, lastCell, sheetName]),
        uploadFile = useCallback(() => Excel.run(context => {
          if(fileNameRef.current.validateForForm() && groupNameRef.current.validateForForm() && fileTypeRef.current.validateForForm()) {
            GAUtils.sendEvent({
              category: "upload",
              action: "upload_file",
              label: "upload"
            })
            const sheet = context.workbook.worksheets.getItem(sheetName)
            const selectedRange = sheet.getRange(`${firstCell}:${lastCell}`)
            selectedRange.load("text")
            return context.sync().then(() => {
              const values = selectedRange.text,
                    csvContent = Papa.unparse(values)
              let fileNameToUpload = fileName.trim()
              if(!fileNameToUpload.toLowerCase().endsWith(EXTENSION)) {
                fileNameToUpload += EXTENSION
              }
              batch(() => {
                dispatch(fileUploadActions.uploadFile({ fileToUpload: csvContent, groupName, fileName: fileNameToUpload, fileType, fields }))
                dispatch(basicUploadActions.clear())
              })
            })
          } else {
            return Promise.resolve()
          }
        }), [dispatch, fields, fileName, fileType, firstCell, groupName, lastCell, sheetName]),
        openFile = useCallback(componentStatuses => {
          const componentStatusesFiltered = componentStatuses.filter(cs => cs.component === DATALAKE)
          const fileId = componentStatusesFiltered?.[0]?.payload?.fileId || componentStatusesFiltered?.[1]?.payload?.fileId
          if(!fileId) {
            return;
          }
          CommonService.openInNewTab(`${DASHBOARD_URL}${routing.EXTERNAL.dataLake.url}?query=uuid=${fileId}#${routing.EXTERNAL.dataLake.routing.files.name}`)
        }, [])

  useEffect(() => () => {
    dispatch(basicUploadActions.clear())
  }, [dispatch])

  return (
    <div className="ng-office-app__authed__content ng-office-app__authed__content--upload">
      <HeaderNoSearch
          icon={(
            <UploadIcon
                color="grey-dark"
                height={20}
                width={20}
            />
          )}
          title="UPLOAD FILE TO DATA LAKE"
      />
      <div className="ng-office-app__authed__content__body">
        <DefineFile
            onChangeRaw={onChangeRaw}
        />
        <When condition={firstCell && lastCell && sheetName}>
          {() => (
            <FileConfig
                fileNameRef={fileNameRef}
                fileTypeRef={fileTypeRef}
                groupNameRef={groupNameRef}
                onChangeRaw={onChangeRaw}
            />
          )}
        </When>
      </div>
      <div className="ng-office-app__authed__content__footer">
        <Button
            disabled={errors?.length > 0 || !isFilledOut}
            onClick={uploadFile}
        >
          Upload
        </Button>
        <div/>
      </div>
      <StatusesBox
          clearJobIds={clearJobIds}
          hidden={areStatusesHidden}
          jobIdObjects={jobIds}
          openItem={openFile}
          setHidden={setHidden}
          statusType={statusType.ETL}
      />
    </div>
  )
}

export default Upload;