import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { AuthApi, TimeService } from 'dashboard-services';
import { globalActions, loginActions, LoginPage } from 'primary-components';

import { basicSettingsActions } from 'actions/settings';
import getApiConfig from 'api/ApiConfig';
import properties from 'resources/constants/properties.json';
import { routing } from 'routing';
import { AuthUtils, GAUtils } from 'utils';

const LOGIN_RESPONSE = 'LOGIN_RESPONSE'
const loginChannel = new BroadcastChannel(LOGIN_RESPONSE)

const LoginPageWrapper = ({ activeComponent }) => {
  const dispatch = useDispatch(),
        onSubmit = useCallback(({ token, withClose = false }) => 
          new AuthApi(dispatch(getApiConfig()))
            .getUserSettings(token, { entitlements: true })
            .withErrorHandler((_, { message }) => {
              dispatch(globalActions.getMessageStore()).error(message)
              dispatch(getApiConfig()).onLogOut()
            })
            .build()
            .call()
            .then(response => {
              if(AuthUtils.hasAccessToExcel({ uiEntitlements: response?.uiEntitlements })) {
                GAUtils.sendEvent({
                  category: "login",
                  action: "Log In",
                  label: "click"
                })
                withClose && Office.addin.hide()
                dispatch(basicSettingsActions.onChange(response?.settings?.userName, "userName"))
                dispatch(basicSettingsActions.onChange(response?.settings?.definedTime || TimeService.UTC, "definedTime"))
                dispatch(basicSettingsActions.onChange(response?.settings?.company, "company"))
              } else {
                throw new Error("User does not have sufficient permission to use excel add-in. Please contact support.")
              }
            })
            .catch(e => {
              dispatch(AuthUtils.sendError({ error: "(LoginPageSubmit)" + e.stack }))
              dispatch(getApiConfig()).onLogOut()
            })
        , [dispatch]),
        onSubmitwithClose = useCallback(props => onSubmit({ withClose: true, ...props }), [onSubmit]),
        isLoginPage = useMemo(() => activeComponent === routing.LOGIN.name, [activeComponent]),
        handleLogin = useCallback(({ onSuccess, onFailure, falbackDialog }) => {
          Office.context.ui.displayDialogAsync(
            `${location.protocol}//${location.hostname}${(location.port ? ":" + location.port : "")}/${falbackDialog}.html`,
            { height: 60, width: 30 },
            asyncResult => {
              if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                console.error("Dialog failed to open", asyncResult.error);
                onFailure(asyncResult.error.message)
              } else {
                const dialog = asyncResult.value;
                dialog.addEventHandler(
                  Office.EventType.DialogMessageReceived,
                  arg => {
                    const messageFromDialog = JSON.parse(arg.message)
                    if (messageFromDialog.status === "success") {
                      onSubmit({ token:  messageFromDialog?.result?.accessToken })
                        .then(() => onSuccess({ token: messageFromDialog?.result?.accessToken }))
                        .catch(e => {
                          dispatch(AuthUtils.sendError({ error: "(HandleLogin)" + e.stack }))
                          return onFailure(e?.message)
                        })
                      dialog.close()
                    } else if(messageFromDialog.status === "failure") {
                      onFailure(messageFromDialog?.result?.message)
                      dialog.close()
                    }
                  }
                )
              }
            }
          )
        }, [dispatch, onSubmit]),
        handleMsLogin = useCallback(props => handleLogin({ falbackDialog: routing.FALL_BACK_DIALOG.MS.name, ...props }), [handleLogin]),
        handleAuth0Login = useCallback(props => handleLogin({ falbackDialog: routing.FALL_BACK_DIALOG.AUTH_0.name, ...props }), [handleLogin])

  useEffect(() => {
    loginChannel.onmessage = e => {
      if(e.data.type === LOGIN_RESPONSE) {
        dispatch(loginActions.loginResponseReducer(e.data.token, e.data.source))
        isLoginPage && Office.addin.hide()
      }
    }
  }, [dispatch, isLoginPage])

  return (
    <LoginPage
        {...properties}
        clientId="disabled"
        handleOuterAuth0Login={handleAuth0Login}
        handleOuterMsLogin={handleMsLogin}
        outerOnSubmit={isLoginPage ? onSubmitwithClose : onSubmit}
    />
  )
}

LoginPageWrapper.propTypes = {
  activeComponent: PropTypes.string.isRequired
}

export default LoginPageWrapper;