import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { basicCurvesActions } from 'actions/curves';

import CurveCreator from './CurveCreator';

import './AddNewCurve.scss';

const AddNewCurve = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(basicCurvesActions.clear())
  }, [dispatch])

  return (
    <div className="ng-office-app__authed__content__body__item ng-office-app__authed__content__body__item--add-curve">
      <CurveCreator/>
    </div>
  )
}

export default AddNewCurve;