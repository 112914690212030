import { createRoot } from 'react-dom/client';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';

import { initialState, officeApp } from 'reducers';
import properties from 'resources/constants/properties.json';

import App from './App';

const title = "NorthGravity Add-in";
const FUNCTIONS_NAMESPACE = properties.functionsNamespace

let rootElement, root

if (properties.version === "LOCALHOST" && module.hot) {
  module.hot.accept();
} else {
  rootElement = document.getElementById('container');
  root = createRoot(rootElement)
}

const renderApp = () =>
  root.render(
    <Provider store={window["ngStore" + FUNCTIONS_NAMESPACE]}>
      <App title={title}/>
    </Provider>
  )

const onReady = () => {
  if(!window["ngStore" + FUNCTIONS_NAMESPACE]) {
    window["ngStore" + FUNCTIONS_NAMESPACE] = createStore((state, action) => officeApp(state, action), initialState, applyMiddleware(thunkMiddleware))
  }
  renderApp();  
}

if(properties.version === "LOCALHOST") {
  document.addEventListener('DOMContentLoaded', () => {
    if (!root) {
      rootElement = document.getElementById('container');
      root = createRoot(rootElement)
    }
    onReady()
  });
} else {
  if(Office.addin) {
    Office.addin.setStartupBehavior?.(Office.StartupBehavior.load)
    onReady()
  } else {
    Office.onReady(() => {
      Office.addin?.setStartupBehavior?.(Office.StartupBehavior.load)
      onReady()
    })
  }
}