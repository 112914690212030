import { connect } from 'react-redux';

import { basicCurvesActions } from 'actions/curves';

import Preview from './Preview.js';

import { createSelector } from 'reselect';

const getRoot = basicState => basicState.root
const getProduct = basicState => basicState.product
const getSymbols = createSelector([getRoot], root => root ? { [basicCurvesActions.SYMBOL]: `${root}_0*` } : undefined)
const getMetadatas = createSelector([getRoot, getProduct], (root, product) => {
  if(!product && !root) {
    return undefined;
  }
  const md = {}
  if(product) {
    md[basicCurvesActions.PRODUCT] = product;
  }
  if(root) {
    md[basicCurvesActions.ROOT] = root;
  }
  return md;
 })

const mapStateToProps = state => {
  const basicState = state.curvesState.basicState
  return {
    groupName: basicState.groupName,
    symbols: getSymbols(basicState),
    metadatas: getMetadatas(basicState),
    query: basicState.query,
    isEditingKey: !!basicState.address,
    shouldAllowSubmit: !!basicState.product && !!basicState.root && !!basicState.column && !!basicState.groupName
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: () => dispatch(basicCurvesActions.addData()),
  onClear: () => dispatch(basicCurvesActions.clear())
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview)