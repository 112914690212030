import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Dropdown, FormRow, Label, Running, Settings, TextField } from 'basic-components';
import { AuthApi, ReduxService, TimeService } from 'dashboard-services';
import { globalActions, loginActions } from 'primary-components';

import { basicSettingsActions } from 'actions/settings';
import getApiConfig from 'api/ApiConfig';
import properties from 'resources/constants/properties.json';
import { routing } from 'routing';
import { AuthSingleton, GAUtils, LocalStorageUtils } from 'utils';

import HeaderNoSearch from 'primary/HeaderNoSearch';

import { When } from 'react-if';

import './Settings.scss';

const timeZones = TimeService.TIME_ZONES.map(t => ReduxService.updateObject(t, { tooltipPosition: "top" }))
const SettingsComponent = ({ isLoggedIn }) => {
  const dispatch = useDispatch(),
        definedTime = useSelector(state => state.settingsState.definedTime),
        defaultApiUrl = useMemo(() => AuthSingleton.getInstance().getApiUrl(), []),
        defaultTimezoneObject = useMemo(() => timeZones.find(c => c.value === definedTime), [definedTime]),
        [apiUrl, setApiUrl] = useState(defaultApiUrl),
        [timezone, setTimezone] = useState(definedTime),
        saveApiUrl = useCallback(({ timezoneLocal = timezone, apiUrlLocal = apiUrl }) => {
          let promise = Promise.resolve()
          if(definedTime !== timezoneLocal && isLoggedIn && timeZoneRef.current.validateForForm()) {
            promise = new AuthApi(dispatch(getApiConfig()))
              .updateUserSettings({ 
                definedTime: timeZones.find(s => s?.value === timezoneLocal)?.value || TimeService.UTC
              })
              .build()
              .call()
              .then(() => dispatch(globalActions.getMessageStore()).success("Settings have been saved."))
              .then(() => dispatch(basicSettingsActions.onChange(timeZones.find(s => s?.value === timezoneLocal)?.value || TimeService.UTC, "definedTime")))
          }
          if(apiUrlLocal !== defaultApiUrl && apiUrlRef.current.validateForForm()) {
            promise.then(() => {
              if(isLoggedIn) {
                dispatch(loginActions.logOut(false))
              }
              LocalStorageUtils.setApiUrl(apiUrlLocal)
              dispatch(globalActions.getMessageStore()).success("API URL has been changed.")
            })
          }
        }, [apiUrl, defaultApiUrl, definedTime, dispatch, isLoggedIn, timezone]),
        resetValues = useCallback(() => {
          setApiUrl(properties.baseUrl.api)
          setTimezone(TimeService.UTC)
          timeZoneRef.current.dropdown?.current?.setValue({ label: TimeService.UTC, value: TimeService.UTC })
          dispatch(globalActions.getToastsStore()).warning("Values have been reset to default. Do you want to save them now?", "Values have been reset to default.", {
            withCancel: false,
            confirmText: "Save",
            confirmAction: () => saveApiUrl({ timezoneLocal: TimeService.UTC, apiUrlLocal: properties.baseUrl.api })
          })
        }, [dispatch, saveApiUrl]),
        apiUrlRef = useRef(),
        timeZoneRef = useRef()

  useEffect(() => {
    GAUtils.sendEvent({
      category: "open",
      action: "open_settings",
      label: "open"
    })
    GAUtils.sendPageView(routing.SETTINGS.name)
  }, [])

  return (
    <div 
        className="ng-office-app__settings"
    >
      <HeaderNoSearch
          icon={(
            <Settings
                color="grey-dark"
                height={20}
                width={20}
            />
          )}
          title="ADD-IN SETTINGS"
      />
      <div className="ng-office-app__settings__body">
        <div className="ng-office-app__settings__body__section">
          <div className="ng-office-app__settings__body__section__title">
            ADD-IN VERSION
          </div>
          <FormRow 
              className="ng-office-app__settings__body__section__body"
              isVertical
          >
            <Label   
                size="big"
            >
              Version: {properties.version}
            </Label>
          </FormRow>
        </div>
        <div className="ng-office-app__settings__body__section">
          <div className="ng-office-app__settings__body__section__title">
            API CONFIGURATION
          </div>
          <FormRow 
              className="ng-office-app__settings__body__section__body"
              isVertical
          >
            <Label>API URL</Label>
            <TextField
                defaultValue={apiUrl}
                name="apiUrl"
                onChange={setApiUrl}
                ref={apiUrlRef}
                required
                variant="border"
            />
          </FormRow>
        </div>
        <When condition={isLoggedIn}>
          {() => (
            <div className="ng-office-app__settings__body__section">
              <div className="ng-office-app__settings__body__section__title">
                USER SETTINGS
              </div>
              <FormRow
                  className="ng-office-app__settings__body__section__body"
                  isVertical
              >
                <Label>User time zone</Label>
                <Dropdown
                    defaultValue={defaultTimezoneObject}
                    name="definedTime"
                    onChange={setTimezone}
                    options={timeZones}
                    ref={timeZoneRef}
                    required
                    variant="border"
                />
              </FormRow>
            </div>
          )}
        </When>
      </div>
      <div className="ng-office-app__settings__footer">
        <Button
            onClick={resetValues}
            size="small"
            variant="text"
        >
          <Running
              bgColor="white-base"
              color="blue-bright"
              height={16}
              width={16}
          />
          <strong>
            Reset
          </strong>
        </Button>
        <Button
            disabled={apiUrl === defaultApiUrl && (definedTime === timezone || !isLoggedIn)}
            onClick={saveApiUrl}
        >
          Save
        </Button>
        <div/>
      </div>
    </div>
  )
}

SettingsComponent.defaultProps = {
  isLoggedIn: false
}

SettingsComponent.propTypes = {
  isLoggedIn: PropTypes.bool
}

export default SettingsComponent;